import styled from "styled-components";
import { Colors, Device } from "../constants";

//#region Styles
const Component = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    width: 100%;
    background-color: white;
    font-size: 1.2rem;
    overflow: hidden;

    @media ${Device.mobileS} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
        width: 100%;
    }
`;
const Title = styled.span`
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.6rem;
    }
`;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;
    width: 100%;
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    padding: .5rem;
    max-width: 50%;
    min-width: 30%;
    text-align: center;

    @media ${Device.mobileS} {
        min-width: 30%;
    }

    @media ${Device.mobileM} {
        min-width: 35%;
    }

    @media ${Device.mobileL} {
        min-width: 35%;
    }

    @media ${Device.tablet} {
        min-width: 20%;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.mobileS} {
        font-size: 0.8rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.8rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.8rem;
    }

    @media ${Device.tablet} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.5rem;
    color: ${props => props.color};

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
//#endregion

export default function Nutrients(props) {
    const c = props.portionCount !== undefined ? (props.calorie/props.portionCount).toFixed(2)+' ('+props.calorie.toFixed(2)+')' : props.calorie.toFixed(2);
    const p = props.portionCount !== undefined ? (props.protein/props.portionCount).toFixed(2)+' ('+props.protein.toFixed(2)+')' : props.protein.toFixed(2);
    const f = props.portionCount !== undefined ? (props.fat/props.portionCount).toFixed(2)+' ('+props.fat.toFixed(2)+')' : props.fat.toFixed(2);
    const ca = props.portionCount !== undefined ? (props.carbs/props.portionCount).toFixed(2)+' ('+props.carbs.toFixed(2)+')' : props.carbs.toFixed(2);
    const titleText = props.title !== undefined ? props.title : 'Энергетическая ценность';
    return <Component>
        { !props.hideTitle && <Title>{titleText}</Title> }
        <NutrientsList>
            <NutrientItem>
                <NutrientTitle>Калории</NutrientTitle>
                <NutrientValue color='#1e7390'>{c}</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Белки</NutrientTitle>
                <NutrientValue color='#07bc0c'>{p}</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Жиры</NutrientTitle>
                <NutrientValue color={Colors.peach}>{f}</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Углеводы</NutrientTitle>
                <NutrientValue color={Colors.red}>{ca}</NutrientValue>
            </NutrientItem>
        </NutrientsList> 
    </Component>
}