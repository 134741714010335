import styled from 'styled-components';
import {Colors, Modals, Device} from '../../constants';
import { inject, observer } from "mobx-react";
import Button from '../../components/button';
import { ReactComponent as IconDelete } from "../../icons/delete.svg";
import { toast } from 'react-toastify';

const Component = styled.div`
    height: 100%;
    
    overflow: auto;
`;
const EmptyPage = styled.span`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    color: black;
`;
const Table = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
`;

const TableHead = styled.th`
    padding: .5rem;
    font-family: 'Amatic SC', cursive;
    font-size: 1.5rem;
    width: 10px;

    @media ${Device.mobileS} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.25rem;
    }
    
    @media ${Device.tablet} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const TableRow = styled.tr`
    border-bottom: 1px solid lightgray;

    &:hover {
        background-color: ${props => props.isHead ? 'transparent' : Colors.light_green};
        //color: white;
    }
`;
const TableData = styled.td`
    padding: .5rem 1rem;

    @media ${Device.mobileS} {
        font-size: .8rem;
    }

    @media ${Device.mobileM} {
        font-size: .8rem;
    }

    @media ${Device.mobileL} {
        font-size: .8rem;
    }

    @media ${Device.tablet} {
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const DataButton = styled.div`
    justify-content: space-between;
    align-items: center;
    display: flex;
`;
const DeleteButton = styled(IconDelete)`
    fill: ${Colors.red};
    height: 20px;
    width: 20px;
`;
function ProductsTable(props) {
    const products = props.products;

    if (products === undefined || products.length === 0) {
        return <EmptyPage>Тут пока пусто</EmptyPage>
    }

    function deleteProduct(id) {
        props.store.Products.delete(id)
        .then(res => {
            toast.success('Удалено')
            props.store.Products.loadingData();
        })
        .catch(error => {
            toast.error(error);
        })
    }

    return <Component>
        <Table>
            <TableRow isHead={true}>
                <TableHead>Название</TableHead>
                {/* <TableHead>Описание</TableHead> */}
                <TableHead>Белки</TableHead>
                <TableHead>Жиры</TableHead>
                <TableHead>Углеводы</TableHead>
                <TableHead>Калории</TableHead>
            </TableRow>
        {
            products.map(product => <TableRow onClick={() => product.can_edit === 0 ? null : props.store.Global.setModal(Modals.edit_product.name, product)}>
                <TableData>{product.name}</TableData>
                {/* <TableData>{product.description}</TableData> */}
                <TableData>{product.protein}</TableData>
                <TableData>{product.fat}</TableData>
                <TableData>{product.carbs}</TableData>
                <TableData>{product.calorie}</TableData>
                {/* <TableData>{product.can_edit === 1 && <DeleteButton onClick={(event) => {event.stopPropagation(); deleteProduct(product.id)}}/>}</TableData> */}
            </TableRow>)
        }
        </Table>
    </Component>
}

export default inject("store")(observer(ProductsTable));