import styled from 'styled-components';
import * as vars from '../../constants';
import { inject, observer } from "mobx-react";
import Button from '../../components/button';
import Table from './table';
import { useEffect, useState } from 'react';
import { Device, Modals } from '../../constants';

const Component = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    border-radius: .5rem;
    height: 100%;
    margin: 1rem;
    margin-top: 2.5rem;
    padding: 1rem;
    background-color: white;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;
    border: 1px solid lightgray;
    overflow: hidden;

    @media ${Device.mobileS} {
        margin-top: 1.5rem;
    }

    @media ${Device.mobileM} {
        margin-top: 1.5rem;
    }

    @media ${Device.mobileL} {
        margin-top: 1.5rem;
    }

    @media ${Device.tablet} {
        margin-top: 1.5rem;
    }

    @media ${Device.laptopS} {
        margin-top: 1.5rem;
    }

    @media ${Device.laptopM} {
        margin-top: 2.5rem;
    }

    @media ${Device.laptopL} {
        margin-top: 2.5rem;
    }
`;
const ControlsSection = styled.div`
    flex-direction: row;
    justify-content: space-between;
    display: flex;

    @media ${Device.mobileS} {
        //flex-direction: column;
        font-size: .8rem;
    }

    @media ${Device.mobileM} {
        flex-direction: row;
        font-size: .8rem;
    }

    @media ${Device.mobileL} {
        font-size: .8rem;
    }

    @media ${Device.tablet} {
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const ButtonRow = styled.div`
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    display: flex;
`;
const HelpSection = styled.div`

    @media ${Device.mobileS} {
        font-size: .8rem;
    }

    @media ${Device.mobileM} {
        font-size: .8rem;
    }
    @media ${Device.mobileL} {
        font-size: .8rem;
    }

    @media ${Device.tablet} {
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const ProductList = styled.div`
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    gap: .75rem;
    overflow-y: auto;
`;
const ProductItem = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 300px;
    border: 1px solid lightgray;
    border-radius: .25rem;
    padding: .5rem;
    cursor: pointer;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }
    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: calc((100% - 1.5rem) / 3);
    }

    @media ${Device.laptopS} {
        width: calc((100% - 2.25rem) / 4);
    }

    @media ${Device.laptopM} {
        width: calc((100% - 3rem) / 5);
    }

    @media ${Device.laptopL} {
        width: calc((100% - 3.75rem) / 6);
    }
`;
const ProductItemMain = styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
    gap: .25rem;
`;
const ProductItemName = styled.span`
    font-family: 'Amatic SC', cursive;
    font-size: 1.5rem;
    font-weight: 600;
`;
const ProductItemNutrientList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
const ProductItemNutrient = styled.span`
    display: flex;
    align-items: center;
    font-size: smaller;
    &:before {
        content: '●';
        color: ${props => props.color};
        padding-right: .25rem;
    }
`
const ProdcutItemCalorie = styled.span`
    color: gray;
    font-size: smaller;
`;
const NutrientName = styled.span`
    font-weight: 500;
    color: ${props => props.color};
`;
const Row = styled.div`
    flex-direction: row;
    display: flex;
`;
const ShowSpan = styled.span`
    display: block;
    font-size: .8rem;

    @media ${Device.mobileS} {
        display: none;
    }

    @media ${Device.mobileM} {
        display: none;
    }
    @media ${Device.mobileL} {
        display: block;
        font-size: .8rem;
    }

    @media ${Device.tablet} {
        display: block;
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        display: block;
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        display: block;
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        display: block;
    }
`;
const EmptyPage = styled.span`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    color: black;
`;
function Products(props) {
    const products = props.store.Products.products;
    const [filterType, setFilterType] = useState(0);
    const [filteredProducts, setFilteredProducts] = useState(products);
    useEffect(() => {
        filterList();
    }, [products])
    useEffect(() => {
        filterList();
    }, [filterType])

    function filterList() {
        switch (filterType) {
            case 1:
                setFilteredProducts(products.filter(p => p.is_public));
                break;
            case 2:
                setFilteredProducts(products.filter(p => !p.is_public));    
                break;
            default:
                setFilteredProducts(products);    
                break;
        }
    }


    return <Component>
        <HelpSection>
                <p><NutrientName color='green'>Белки</NutrientName>, <NutrientName color={vars.Colors.peach}>жиры</NutrientName>, <NutrientName color={vars.Colors.red}>углеводы</NutrientName>, <NutrientName color='black'>калории</NutrientName> указаны на 100 грамм продукта.</p>
        </HelpSection>
        <ControlsSection>
            <Button onClick={() => props.store.Global.setModal('create_product')}>Добавить</Button>
            <ButtonRow>
                <ShowSpan>Показывать</ShowSpan>
                <Row>
                    <Button isGroup={true} isLeft={true} isActive={filterType === 0} onClick={() => setFilterType(0)}>Все</Button>
                    <Button isGroup={true}  isActive={filterType === 1}onClick={() => setFilterType(1)}>Общие</Button>
                    <Button isGroup={true} isRight={true} isActive={filterType === 2} onClick={() => setFilterType(2)}>Мои</Button>
                </Row>
            </ButtonRow>
        </ControlsSection>
        <ProductList>
        {
            filteredProducts.map((p,i) => <ProductItem key={i} onClick={() => p.can_edit === 0 ? null : props.store.Global.setModal(Modals.edit_product.name, p)}>
                <ProductItemMain>
                    <ProductItemName>{p.name}</ProductItemName>
                    <ProductItemNutrientList>
                        <ProductItemNutrient color='green'>{p.protein}</ProductItemNutrient>
                        <ProductItemNutrient color={vars.Colors.peach}>{p.fat}</ProductItemNutrient>
                        <ProductItemNutrient color={vars.Colors.red}>{p.carbs}</ProductItemNutrient>
                    </ProductItemNutrientList>
                </ProductItemMain>
                <ProdcutItemCalorie>{p.calorie} ккал</ProdcutItemCalorie>
            </ProductItem>)
        }
        {
            filteredProducts.length === 0 ? <EmptyPage>Тут пока пусто</EmptyPage> : null
        }
        </ProductList>
        {/* <Table products={filteredProducts}/> */}
    </Component>
}

export default inject("store")(observer(Products));