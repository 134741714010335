export const getToken = function() {
    return localStorage.getItem('token') || '';
}

export function setToken(_token) {
    localStorage.setItem('token', _token);
}

export function removeToken() {
    localStorage.removeItem('token');
}