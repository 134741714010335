import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ReactLogo } from "../icons/food.svg";
import { Colors } from "../constants";
const Component = styled.div`
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    border: 1px solid ${props => props.border ? 'lightgray' : 'transparent'};
    border-radius: .25rem;
    width: ${props => props.width};
    height: ${props => props.height};
    
`;
const StyledLogo = styled(ReactLogo)`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.width};
    height: ${props => props.height !== 'auto' ? 'calc('+props.height+' * 0.75)': '150px'};
    
`;
const ImageAlt = styled.span`
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    font-family: 'Amatic SC', cursive;
    width: ${props => props.width};
    text-align: center;
    color: ${Colors.peach};
    font-size: 1.5rem;
`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: ${props => props.width};
    height: ${props => props.height};

    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;
`;
export default function CoverImage(props) {
    const width = props.width || '400px';
    const height = props.height || '200px';
    const border = props.border !== undefined;
    
    return <Component width={width} height={height} border={border}>
        {
            props.image === null && <>
                <StyledLogo width={width} height={height}/>
                <ImageAlt width={width} height={height}>Что-то вкусненькое</ImageAlt>
            </>
        }
        {   
            props.image !== null && <ImagePreview  width={width} height={height} src={props.image}/>
        }
    </Component>
}