import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/menu_item_card";
import Weekday from "../components/weekday";
import { Meals, Colors, Device } from "../constants";
import Nutrients from "../components/nutrient";

const Component = styled.div`
    flex-direction: column;
    gap: 0.5rem;
    display: flex;
    padding: 2rem;
    overflow: auto;

    @media ${Device.mobileS} {
        padding: 1rem;
        padding-top: 1.5rem;
    }

    @media ${Device.mobileM} {
        padding: 1rem;
        padding-top: 1.5rem;
    }

    @media ${Device.mobileL} {
        padding: 1rem;
        padding-top: 1.5rem;
    }

    @media ${Device.tablet} {
        padding: 1rem;
        padding-top: 1.5rem;
    }

    @media ${Device.laptopS} {
        padding: 1rem;
    }

    @media ${Device.laptopM} {
        padding: 1rem;
    }

    @media ${Device.laptopL} {
        padding: 2rem;
    }
`;
const HiTitle = styled.span`
    font-size: 2rem;
    font-weight: 600;

    @media ${Device.mobileS} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.25rem;
    }

    @media ${Device.tablet} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 2rem;
    }
`;
const TodayText = styled.span`
    font-size: 1.5rem;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const MealTitle = styled.span`
    font-size: 2rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 2rem;
    }
`;
const MenuList = styled.div`
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    display: flex;
    width: 100%;
`;
const MealCard = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;

`;
const MenuItemList = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
    flex-wrap: wrap;
`;
const MenuItemCard = styled.div`
    flex-direction: column;
    display: flex;
    background-color: white;
    min-width: 300px;
`;
const Empty = styled.span`
    padding: .25rem;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 1.3rem;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.3rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.3rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.3rem;
    }
`;
const NutrientSection = styled.div`
    justify-content: center;
    text-align: center;
    display: flex;
    width: 15%;
    max-height: 550px;
    /* padding: .5rem;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: .5rem; */

    @media ${Device.mobileS} {
        max-height: 400px;
        width: 100%;
        flex-direction: column;
    }

    @media ${Device.mobileM} {
        max-height: 400px;
        width: 100%;
        flex-direction: column;
    }

    @media ${Device.mobileL} {
        max-height: 400px;
        width: 100%;
        flex-direction: column;
    }

    @media ${Device.tablet} {
        max-height: 400px;
        width: 100%;
        flex-direction: column;
    }

    @media ${Device.laptopS} {
        max-height: 450px;
        width: 15%;
        flex-direction: column;
    }

    @media ${Device.laptopM} {
        max-height: 450px;
        width: 20%;
        flex-direction: column;
    }

    @media ${Device.laptopL} {
        max-height: 550px;
        width: 15%;
        flex-direction: column;
    }
`;
const NutrientTotal = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    border-radius: .5rem;
    border: 1px solid lightgray;
    background-color: white;
    font-size: 1.5rem;
    overflow: hidden;
    width: 100%;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;

    @media ${Device.mobileS} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.mobileM} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.mobileL} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.tablet} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.laptopS} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.laptopM} {
        gap: .5rem;
        font-size: 1rem;
        padding: .5rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        font-size: 1.5rem;
        padding: 1rem;
    }
`;
const NutrientsList = styled.div`
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    display: flex;
    padding: .5rem;
    flex-wrap: wrap;

    @media ${Device.mobileS} {
        gap: .5rem;
        flex-direction: row;
    }

    @media ${Device.mobileM} {
        gap: .5rem;
        flex-direction: row;
    }

    @media ${Device.mobileL} {
        gap: .5rem;
        flex-direction: row;
    }

    @media ${Device.tablet} {
        gap: .5rem;
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        gap: .5rem;
        flex-direction: column;
    }

    @media ${Device.laptopM} {
        gap: .5rem;
        flex-direction: column;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        flex-direction: column;
    }
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    min-height: 100px;
    text-align: center;

    @media ${Device.mobileS} {
        min-height: 50px;
        min-width: 40%;
    }

    @media ${Device.mobileM} {
        min-height: 50px;
        min-width: 40%;
    }

    @media ${Device.mobileL} {
        min-height: 50px;
        min-width: 40%;
    }

    @media ${Device.tablet} {
        min-height: 50px;
        min-width: 20%;
    }

    @media ${Device.laptopS} {
        min-height: 75px;
        min-width: 30%;
    }

    @media ${Device.laptopM} {
        min-height: 75px;
    }

    @media ${Device.laptopL} {
        min-height: 100px;
    }
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.mobileS} {
        font-size: .7rem;
    }

    @media ${Device.mobileM} {
        font-size: .7rem;
    }

    @media ${Device.mobileL} {
        font-size: .7rem;
    }

    @media ${Device.tablet} {
        font-size: .7rem;
    }

    @media ${Device.laptopS} {
        font-size: .8rem;
    }

    @media ${Device.laptopM} {
        font-size: .8rem;
    }

    @media ${Device.laptopL} {
        font-size: .9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.75rem;
    color: ${props => props.color};

    @media ${Device.mobileS} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.25rem;
    }

    @media ${Device.tablet} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.75rem;
    }
`;
const D = styled.div`
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    display: flex;

    @media ${Device.mobileS} {
        flex-direction: column;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: column;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;
function Main(props) {
    const storeMenu = toJS(props.store.Menus.menus);
    const userData = toJS(props.store.User.user);
    const [currentMenu, setCurrentMenu] = useState(undefined);
    const [init, setInit] = useState(false);
    const [breakfastList, setBreakfastList] = useState([]);
    const [lunchList, setLunchList] = useState([]);
    const [dinnerList, setDinnerList] = useState([]);
    const [snackList, setSnackList] = useState([]);
    const [dayMealList, setDayMealList] = useState([]);

    const [calorie, setCalorie] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbs, setCarbs] = useState(0);

    useEffect(() => {
        if (init) return;
        const menu = storeMenu.find(m => new Date(m.menu_date).toISOString().substring(0,10) === new Date().toISOString().substring(0,10));
        if (menu !== undefined) {
            setCurrentMenu(menu);
            setInit(true);
        }
    }, [storeMenu])

    useEffect(() => {
        if (currentMenu !== undefined) {
            let bl = [];
            let ll = [];
            let dl = [];
            let sl = [];
            let md = [];
            
            let sumTotalCalorie = 0;
            let sumTotalProtein = 0;
            let sumTotalFat = 0;
            let sumTotalCarbs = 0;
            currentMenu.items.forEach(item => {
                if (item.item_type === Meals.day_meal.name) {
                    md.push(item);
                }

                if (item.item_type === Meals.breakfast.name) {
                    bl.push(item);
                }

                if (item.item_type === Meals.lunch.name) {
                    ll.push(item)
                }

                if (item.item_type === Meals.dinner.name) {
                    dl.push(item)
                }

                if (item.item_type === Meals.snack.name) {
                    sl.push(item)
                }

                
                if (item.product !== null) {
                    sumTotalCalorie += item.product.calorie/100*item.weight;
                    sumTotalProtein += item.product.protein/100*item.weight;
                    sumTotalFat += item.product.fat/100*item.weight;
                    sumTotalCarbs += item.product.carbs/100*item.weight;
                }
        
                if (item.recipe !== null) {
                    let sumCalorie = 0;
                    let sumProtein = 0;
                    let sumFat = 0;
                    let sumCarbs = 0;
        
                    item.recipe.products.forEach(p => {
                        sumCalorie += p.product.calorie/100*p.weight;
                        sumProtein += p.product.protein/100*p.weight;
                        sumFat += p.product.fat/100*p.weight;
                        sumCarbs += p.product.carbs/100*p.weight;
                    })
                    sumTotalCalorie += sumCalorie/item.recipe.portion_count;
                    sumTotalProtein += sumProtein/item.recipe.portion_count;
                    sumTotalFat += sumFat/item.recipe.portion_count;
                    sumTotalCarbs += sumCarbs/item.recipe.portion_count;
                }
            })

            setCalorie(sumTotalCalorie);
            setProtein(sumTotalProtein);
            setFat(sumTotalFat);
            setCarbs(sumTotalCarbs);

            setBreakfastList(bl);
            setLunchList(ll);
            setDinnerList(dl);
            setSnackList(sl);
            setDayMealList(md);
        }

    }, [currentMenu])

    return <Component>
        <HiTitle>Привет, {userData.first_name}!</HiTitle>
        <TodayText>Твое меню на сегодня</TodayText>
        <D>
        <MenuList>
            <MealCard>
                <MealTitle>{Meals.day_meal.label}</MealTitle>
                <MenuItemList>
                {
                    dayMealList.map(element => <Card data={element}/>)
                }
                {
                    dayMealList.length === 0 && <Empty>Пусто</Empty>
                }
                </MenuItemList>
            </MealCard>
            <MealCard>
                <MealTitle>{Meals.breakfast.label}</MealTitle>
                <MenuItemList>
                {
                    breakfastList.map(element => <Card data={element}/>)
                }
                {
                    breakfastList.length === 0 && <Empty>Пусто</Empty>
                }
                </MenuItemList>
            </MealCard>
            <MealCard>
                <MealTitle>{Meals.lunch.label}</MealTitle>
                <MenuItemList>
                {
                    lunchList.map(element => <Card data={element}/>)
                }
                {
                    lunchList.length === 0 && <Empty>Пусто</Empty>
                }
                </MenuItemList>
            </MealCard>
            <MealCard>
                <MealTitle>{Meals.dinner.label}</MealTitle>
                <MenuItemList>
                {
                    dinnerList.map(element => <Card data={element}/>)
                }
                {
                    dinnerList.length === 0 && <Empty>Пусто</Empty>
                }
                </MenuItemList>
            </MealCard>
            <MealCard>
                <MealTitle>{Meals.snack.label}</MealTitle>
                <MenuItemList>
                {
                    snackList.map(element => <Card data={element}/>)
                }
                {
                    snackList.length === 0 && <Empty>Пусто</Empty>
                }
                </MenuItemList>
            </MealCard>
        </MenuList>
        {
            currentMenu !== undefined && <NutrientSection><NutrientTotal>
            <span>Всего за день</span>
            <NutrientsList>
                <NutrientItem>
                    <NutrientTitle>Калории</NutrientTitle>
                    <NutrientValue color='#1e7390'>{calorie.toFixed(2)}</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Белки</NutrientTitle>
                    <NutrientValue color='#07bc0c'>{protein.toFixed(2)}</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Жиры</NutrientTitle>
                    <NutrientValue color={Colors.peach}>{fat.toFixed(2)}</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Углеводы</NutrientTitle>
                    <NutrientValue color={Colors.red}>{carbs.toFixed(2)}</NutrientValue>
                </NutrientItem>
            </NutrientsList> 
            </NutrientTotal> 
        </NutrientSection>
        }
        
        </D>
    </Component>
}

export default inject("store")(observer(Main));