import { inject } from "mobx-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as EditIconFile } from "../../icons/edit.svg";
import { ReactComponent as DeleteIconFile } from "../../icons/delete.svg";
import { Colors, Device } from "../../constants";
import { toast } from "react-toastify";
import { Modals } from "../../constants";

const RecipeItem = styled.div`
    position: relative;
    flex-direction: column;
    align-items: center;
    display: flex;
    border-radius: .5rem;
    border: 1px solid lightgray;
    //width: 300px;
    /* height: 250px; */
    background-color: white;
    font-family: 'Amatic SC', cursive;
    font-size: 1.75rem;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    flex: 1 0 300px;
    max-width: calc((1920px - 4rem) / 5);// 300px;
    height: 250px;
    box-shadow: #ECB390 0px 3px 10px -8px;


    @media ${Device.mobileS} {
        max-width: calc((1920px - 4rem) / 5);
        height: 250px;
        flex: 1 0 285px;
        font-size: ${props => props.only ? '1.5rem' : '1.3rem'};
    }

    @media ${Device.tablet} {
        max-width: calc((768px - 4rem) / 3);
        height: 200px;
        flex: 1 0 300px;
        font-size: ${props => props.only ? '1.5rem' : '1.3rem'};
    }

    @media ${Device.laptopS} {
        max-width: calc((1000px - 4rem) / 4);
        height: 200px;
        font-size: ${props => props.only ? '1.5rem' : '1.3rem'};
    }

    @media ${Device.laptopM} {
        max-width: calc((1200px - 2rem) / 5);
        height: 200px;
        font-size: ${props => props.only ? '1.5rem' : '1.3rem'};
    }

    @media ${Device.laptopL} {
        max-width: calc((1920px - 4rem) / 5);
        height: 250px;
        font-size: 1.75rem;
    }
`;
const CoverImage = styled.img`
    flex: 1;
    object-fit: cover;
`;
const Text = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    padding: .5rem;
    height: ${props => props.only ? '100%' : '50px'};
`;
const EditButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    display: block;
    height: ${props => props.visible ? 'auto' : '0'};
    padding: .5rem;
    border-bottom-right-radius: .5rem;
    transition: opacity .1s ease-out;
    opacity: ${props => props.visible ? '1' : '0'};

    
`;
const EditIcon = styled(EditIconFile)`
    width: 36px;
    height: 36px;
    fill: green;

    @media ${Device.tablet} {
        width: 24px;
        height: 24px;
    }

    @media ${Device.laptopS} {
        width: 24px;
        height: 24px;
    }

    @media ${Device.laptopM} {
        width: 24px;
        height: 24px;
    }

    @media ${Device.laptopL} {
        width: 36px;
        height: 36px;
    }
`;
const DeleteButton = styled.div`
    position: absolute;
    display: block;
    height: ${props => props.visible ? 'auto' : '0'};
    transition: opacity .1s ease-out;
    opacity: ${props => props.visible ? '1' : '0'};
    top: 0;
    right: 0;
    background-color: rgba(255,255,255,0.8);
    padding: .5rem;
    border-bottom-left-radius: .5rem;
    
`;
const DeleteIcon = styled(DeleteIconFile)`
    width: 36px;
    height: 36px;
    fill: ${Colors.red};

    @media ${Device.tablet} {
        width: 24px;
        height: 24px;
    }

    @media ${Device.laptopS} {
        width: 24px;
        height: 24px;
    }
    
    @media ${Device.laptopM} {
        width: 24px;
        height: 24px;
    }

    @media ${Device.laptopL} {
        width: 36px;
        height: 36px;
    }
`;

const Image = styled.div`
    width: 100%;

    @media ${Device.mobileM} {
        height: 200px;
    }

    @media ${Device.tablet} {
        height: 150px;
    }

    @media ${Device.laptopS} {
        height: 150px;
    }

    @media ${Device.laptopM} {
        height: 150px;
    }

    @media ${Device.laptopL} {
        height: 200px;
    }
`;

function Item(props) {
    const images = props.store.Images.images;
    const [imagePath, setImagePath] = useState(null);
    const [loaded, setLoaded] = useState(false)
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        if (loaded) return;

        if (images !== undefined) {
            const image = images.find(i => i.id === props.data.cover_image_id);
            if (image !== undefined && image.path !== undefined)
            setImagePath(image.path);
            setLoaded(true)
        }
    }, [props.store.Images.images])

    function deleteRecipe(event) {
        event.stopPropagation();
        props.store.Recipes.delete(props.data.id)
        .then(result => {
            toast.success('Рецепт удален');
            props.store.Recipes.loadingData();
        })
        .catch(error => {
            toast.error(error);
        })
    }

    return <RecipeItem onTouchStart={() => setIsShown(true)} onClick={props.onClick} onMouseEnter={() => setIsShown(true)}
    onMouseLeave={() => setIsShown(false)} only={props.data.image === null}>
        {
            props.data.image !== null &&  <Image><CoverImage width='100%' height='100%' src={props.data.image.path}/></Image>
        }
        <Text only={props.data.image === null}>{props.data.name}</Text>
        <EditButton visible={isShown} onClick={(event) => {event.stopPropagation(); props.store.Global.setModal(Modals.edit_recipe.name, props.data)}}>
            <EditIcon />
        </EditButton>
        <DeleteButton visible={isShown} onClick={(event) => deleteRecipe(event)}>
            <DeleteIcon />
        </DeleteButton>
    </RecipeItem>
}

export default inject("store")(Item);