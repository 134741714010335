import { toJS } from "mobx";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Meals, Colors, Device } from "../constants";


const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    border-radius: .5rem;
    border: 1px solid #ECB390;
    padding: 1rem;
    background-color: white;
    min-width: 250px;
    box-shadow: #ECB390 0px 3px 10px -8px;

    @media ${Device.mobileS} {
        gap: 1rem;
        padding: .5rem;
        min-width: 285px;
    }

    @media ${Device.mobileM} {
        gap: 1rem;
        padding: .5rem;
        min-width: 340px;
    }

    @media ${Device.mobileL} {
        gap: 1rem;
        padding: .5rem;
        min-width: 350px;
    }

    @media ${Device.tablet} {
        gap: 1rem;
        padding: .5rem;
        min-width: 250px;
    }

    @media ${Device.laptopS} {
        gap: 1rem;
        padding: .5rem;
        min-width: 134px;
    }

    @media ${Device.laptopM} {
        gap: 1rem;
        padding: .5rem;
        min-width: 170px;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        padding: 1rem;
        min-width: 250px;
    }
`;
const WeekdayTitle = styled.span`
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;

    @media ${Device.mobileS} {
        font-size: 1.2rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.2rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.2rem;
    }

    @media ${Device.tablet} {
        font-size: 1.2rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.15rem;
    }
    
    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const WeekdaySubTitle = styled.span`
    content: ' ';
    height: 1.5rem;
    font-size: 1.5rem;
    font-family: 'Amatic SC', cursive;
    text-align: center;

    @media ${Device.mobileS} {
        height: 1rem;
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        height: 1rem;
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        height: 1rem;
        font-size: 1.25rem;
    }

    @media ${Device.tablet} {
        height: .9rem;
        font-size: 1.15rem;
    }

    @media ${Device.laptopS} {
        height: .9rem;
        font-size: 1.15rem;
    }
    
    @media ${Device.laptopM} {
        height: 1.15rem;
        font-size: 1.15rem;
    }
    
    @media ${Device.laptopL} {
        height: 1.5rem;
        font-size: 1.5rem;
    }
`;
const Titles = styled.div`
    flex-direction: column;
    display: flex;
`;
const MenuItems = styled.div`
    flex-direction: column;
    display: flex;

    border: 1px solid lightgray;
    border-radius: .5rem;
    overflow: hidden;
`;
const Meal= styled.div`
    flex-direction: column;
    display: flex;
`;
const MealTitle = styled.span`
    padding: .25rem;
    background-color: ${Colors.peach};
    color: white;
    font-size: 1.2rem;
    text-align: center;

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const MealItem = styled.span`
    padding: .25rem .5rem;

    @media ${Device.tablet} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const Empty = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25rem;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: ${props => props.large ? '2rem' : '1.3rem'};
    height: 100%;

    @media ${Device.tablet} {
        font-size: ${props => props.large ? '1.5rem' : '1.1rem'};
    }

    @media ${Device.laptopS} {
        font-size: ${props => props.large ? '1.5rem' : '1.1rem'};
    }

    @media ${Device.laptopM} {
        font-size: ${props => props.large ? '1.5rem' : '1.1rem'};
    }

    @media ${Device.laptopL} {
        font-size: ${props => props.large ? '2rem' : '1.3rem'};
    }
`;
const NutrientSection = styled.div`
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    display: flex;
    flex: 1;
    width: 100%;
`;
const NutrientTotal = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    width: 100%;
    background-color: white;
    font-size: 1.2rem;
    overflow: hidden;

    @media ${Device.tablet} {
        font-size: .9rem;
    }

    @media ${Device.laptopS} {
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;

    @media ${Device.tablet} {
        gap: 0.5rem;
    }

    @media ${Device.laptopS} {
        gap: 0.25rem;
    }

    @media ${Device.laptopM} {
        gap: 0.5rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    padding: .5rem;
    max-width: 50%;
    min-width: 40%;
    text-align: center;

    @media ${Device.tablet} {
        padding: .25rem;
    }

    @media ${Device.laptopS} {
        padding: .25rem;
    }

    @media ${Device.laptopM} {
        padding: .5rem;
    }

    @media ${Device.laptopL} {
        padding: .5rem;
    }
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.tablet} {
        font-size: 0.6rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.6rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.5rem;
    color: ${props => props.color};

    @media ${Device.tablet} {
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        font-size: .8rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
function Weekday(props) {
    const data = toJS(props.data);
    const [breakfastList, setBreakfastList] = useState([]);
    const [lunchList, setLunchList] = useState([]);
    const [dinnerList, setDinnerList] = useState([]);
    const [snackList, setSnackList] = useState([]);
    const [init, setInit] = useState(false);
    const [calorie, setCalorie] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbs, setCarbs] = useState(0);

    useEffect(() => {
        if (init || data === undefined) return;

        


        let bl = [];
        let ll = [];
        let dl = [];
        let sl = [];
        
        let sumTotalCalorie = 0;
        let sumTotalProtein = 0;
        let sumTotalFat = 0;
        let sumTotalCarbs = 0;

        data.items.forEach(item => {
            if (item.item_type === Meals.breakfast.name) {
                bl.push(item);
            }

            if (item.item_type === Meals.lunch.name) {
                ll.push(item)
            }

            if (item.item_type === Meals.dinner.name) {
                dl.push(item)
            }

            if (item.item_type === Meals.snack.name) {
                sl.push(item)
            }

            if (item.product !== null) {
                sumTotalCalorie += item.product.calorie/100*item.weight;
                sumTotalProtein += item.product.protein/100*item.weight;
                sumTotalFat += item.product.fat/100*item.weight;
                sumTotalCarbs += item.product.carbs/100*item.weight;
            }
    
            if (item.recipe !== null) {
                let sumCalorie = 0;
                let sumProtein = 0;
                let sumFat = 0;
                let sumCarbs = 0;
    
                item.recipe.products.forEach(p => {
                    sumCalorie += p.product.calorie/100*p.weight;
                    sumProtein += p.product.protein/100*p.weight;
                    sumFat += p.product.fat/100*p.weight;
                    sumCarbs += p.product.carbs/100*p.weight;
                })
                sumTotalCalorie += sumCalorie/item.recipe.portion_count;
                sumTotalProtein += sumProtein/item.recipe.portion_count;
                sumTotalFat += sumFat/item.recipe.portion_count;
                sumTotalCarbs += sumCarbs/item.recipe.portion_count;
            }
        })

        setCalorie(sumTotalCalorie);
            setProtein(sumTotalProtein);
            setFat(sumTotalFat);
            setCarbs(sumTotalCarbs);

        setBreakfastList(bl);
        setLunchList(ll);
        setDinnerList(dl);
        setSnackList(sl);
        setInit(true);

    }, [init, data])

    if (data === undefined) {
        return <Component>
        <WeekdayTitle>{props.weekday !== undefined && new Intl.DateTimeFormat('ru-ru', {weekday: 'long'}).format(new Date(props.weekday))}</WeekdayTitle>
        <Empty large={true} onClick={props.onClick}>Пусто</Empty>
    </Component>
    }


    return <Component onClick={props.onClick}>
        <Titles>
        <WeekdayTitle>{new Intl.DateTimeFormat('ru-ru', {weekday: 'long'}).format(new Date(data.menu_date))}</WeekdayTitle>
        <WeekdaySubTitle>{data.name} {data.name === '' && new Date(props.weekday).toLocaleDateString('ru-ru')}</WeekdaySubTitle>
        </Titles>
        <MenuItems>
            <Meal>
                <MealTitle>{Meals.breakfast.label}</MealTitle>
                {
                    breakfastList.map((element, index) => <MealItem key={index+'_'+element.id}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</MealItem>)
                }
                {
                    breakfastList.length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.lunch.label}</MealTitle>
                {
                    lunchList.map((element, index) => <MealItem key={index+'_'+element.id}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</MealItem>)
                }
                {
                    lunchList.length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.dinner.label}</MealTitle>
                {
                    dinnerList.map((element, index) => <MealItem key={index+'_'+element.id}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</MealItem>)
                }
                {
                    dinnerList.length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.snack.label}</MealTitle>
                {
                    snackList.map((element, index) => <MealItem key={index+'_'+element.id}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</MealItem>)
                }
                {
                    snackList.length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
        </MenuItems>
        <NutrientSection>
            <NutrientTotal>
                <span>Всего за день</span>
                <NutrientsList>
                    <NutrientItem>
                        <NutrientTitle>Калории</NutrientTitle>
                        <NutrientValue color='#1e7390'>{calorie.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Белки</NutrientTitle>
                        <NutrientValue color='#07bc0c'>{protein.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Жиры</NutrientTitle>
                        <NutrientValue color={Colors.peach}>{fat.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Углеводы</NutrientTitle>
                        <NutrientValue color={Colors.red}>{carbs.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                </NutrientsList> 
            </NutrientTotal>
        </NutrientSection>
    </Component>
}

export default Weekday;