import { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputMaterial from '../input_material';
import Button from '../button';
import axios from 'axios';
import { Config, Device } from '../../constants';
import { toast } from 'react-toastify';
import Textarea from '../textarea_material';
const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    align-self: flex-start;
    max-width: 90vw;
`;
const Row = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    display: flex;
    min-width: 350px;
    max-width: 400px;

    @media ${Device.mobileS} {
        min-width: 250px;
        max-width: 100%;
    }
    
    @media ${Device.mobileM} {
        min-width: 300px;
        max-width: 100%;
    }

    @media ${Device.mobileL} {
        min-width: 350px;
        max-width: 400px;
    }

    @media ${Device.tablet} {
        min-width: 350px;
        max-width: 400px;
    }

    @media ${Device.laptopS} {
        min-width: 350px;
        max-width: 400px;
    }

    @media ${Device.laptopM} {
        min-width: 350px;
        max-width: 400px;
    }

    @media ${Device.laptopL} {
        min-width: 350px;
        max-width: 400px;
    }
`;
const InputItem = styled.div`
    @media ${Device.mobileS} {
        width: 40%;
    }
    
    @media ${Device.mobileM} {
        width: 40%;
    }

    @media ${Device.mobileL} {
        width: 35%;
    }

    @media ${Device.tablet} {
        width: 35%;
    }

    @media ${Device.laptopS} {
        width: 35%;
    }

    @media ${Device.laptopM} {
        width: 35%;
    }

    @media ${Device.laptopL} {
        width: 35%;
    }
`;

const fieldIds = {
    name: 'productName',
    description: 'productDescription',
    is_public: 'productIsPublic',
    calorie: 'productCalorie',
    carbs: 'productCarbs',
    protein: 'productProtein',
    fat: 'productFat',
    weight: 'productWeight'
}
export default function Modal(props) {
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productIsPublic, setProductIsPublic] = useState(false);
    const [productCalorie, setProductCalorie] = useState('');
    const [productCarbs, setProductCarbs] = useState('');
    const [productProtein, setProductProtein] = useState('');
    const [productFat, setProductFat] = useState('');
    const [productWeight, setProductWeight] = useState('');

    useEffect(() => {
        if (props.data !== undefined && props.data !== null) {
            const data = props.data;
            setProductId(data.id);
            setProductName(data.name);
            setProductDescription(data.description);
            setProductIsPublic(data.is_public);
            setProductCalorie(data.calorie);
            setProductCarbs(data.carbs);
            setProductProtein(data.protein);
            setProductFat(data.fat);
            setProductWeight(data.weight);
        }
    }, [props])

    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.name:
                setProductName(event.target.value);
                break;
            case fieldIds.description:
                setProductDescription(event.target.value);
                break;
            case fieldIds.is_public:
                setProductIsPublic(event.target.value);
                break;
            case fieldIds.calorie:
                setProductCalorie(event.target.value);
                break;
            case fieldIds.carbs:
                setProductCarbs(event.target.value);
                break;
            case fieldIds.protein:
                setProductProtein(event.target.value);
                break;
            case fieldIds.fat:
                setProductFat(event.target.value);
                break;
            case fieldIds.weight:
                setProductWeight(event.target.value);
                break;
            default:
                break;
        }
    }

    function update() {
        const data = {
            id: productId,
            name: productName,
            description: productDescription,
            is_public: productIsPublic,
            calorie: productCalorie,
            carbs: productCarbs,
            protein: productProtein,
            fat: productFat,
            weight: productWeight
        }

        props.store.Products.update(data)
        .then(result => {
            if (result.data.status) {
                toast.success('Продукт обновлен');
                props.store.Products.loadingData();
                props.store.Global.setModal();
            }
            else {
                toast.error('При обновлении возникла ошибка')
            }
        }).catch(error => {
            toast.error(error)
        })
    }

    function deleteProduct() {
        props.store.Products.delete(productId)
        .then(res => {
            toast.success('Удалено')
            props.store.Products.loadingData();
            props.store.Global.setModal();
        })
        .catch(error => {
            toast.error(error);
        })
    }

    return <Component>
        <InputMaterial type='text' id={fieldIds.name} value={productName} label='Название' onChange={(event => onChange(event))}/>
        <Textarea rows='5' type='text' id={fieldIds.description} value={productDescription} label='Описание' onChange={(event => onChange(event))}/>
        <Row>
            <InputItem>
                <InputMaterial type='number' width='auto' label='Белки'  id={fieldIds.protein} value={productProtein} placeholder='' onChange={(event => onChange(event))}/>
            </InputItem>
            <InputItem>
                <InputMaterial type='number' width='auto' label='Жиры'  id={fieldIds.fat} value={productFat} placeholder='' onChange={(event => onChange(event))}/>
            </InputItem>
            <InputItem>
                <InputMaterial type='number' width='auto' label='Углеводы'  id={fieldIds.carbs} value={productCarbs} placeholder='' onChange={(event => onChange(event))}/>
            </InputItem>
            <InputItem>
                <InputMaterial type='number' width='auto' label='Калории' id={fieldIds.calorie} value={productCalorie} placeholder='' onChange={(event => onChange(event))}/>
            </InputItem>
        </Row>
        <Row>
        { props.data.can_edit === 1 && <Button type='delete' onClick={() => deleteProduct()}>Удалить</Button>}
        <Button onClick={() => update()}>Сохранить</Button>
        
        </Row>
    </Component>
}