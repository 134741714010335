import styled from "styled-components";
import { Device } from "../constants";

const Component = styled.select`
    border: 1px solid lightgray;
    border-radius: .25rem;
    padding: .5rem;
    outline: none;
    font-family: 'Roboto',sans-serif;

    @media ${Device.mobileS} {
        padding: .25rem .5rem;
    }

    @media ${Device.mobileM} {
        padding: .25rem .5rem;
    }

    @media ${Device.mobileL} {
        padding: .25rem .5rem;
    }

    @media ${Device.tablet} {
        padding: .25rem .5rem;
    }

    @media ${Device.laptopS} {
        padding: .25rem .5rem;
    }

    @media ${Device.laptopM} {
        padding: .25rem .5rem;
    }

    @media ${Device.laptopL} {
        padding: .5rem;
    }
`;

export default function Select(props) {
    return <Component {...props}>{props.children}</Component>
}