import { useEffect, useState } from "react";
import styled from "styled-components";
import { Device } from "../constants";
import { ReactComponent as ReactLogo } from "../icons/food.svg";
import CoverImage from "./cover_image";

const Component = styled.div`
    flex-direction: column;
    display: flex;
    background-color: white;
    min-width: 300px;
    max-width: 400px;
    border: 1px solid lightgray;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;

    @media ${Device.mobileS} {
        min-width: 250px;
        max-width: 300px;
        width: 100%;
    }

    @media ${Device.mobileM} {
        min-width: 250px;
        max-width: 350px;
        width: 100%;
    }

    @media ${Device.mobileL} {
        min-width: 250px;
        max-width: 400px;
        width: 100%;
    }

    @media ${Device.tablet} {
        min-width: 250px;
        max-width: 350px;
        width: 100%;
    }

    @media ${Device.laptopS} {
        min-width: 300px;
    }

    @media ${Device.laptopM} {
        min-width: 300px;
    }

    @media ${Device.laptopL} {
        min-width: 300px;
    }
`;
const Cover = styled.div`
    @media ${Device.mobileS} {
        width: calc(100% - 2rem);
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }

    @media ${Device.laptopS} {
        width: 100%;
    }

    @media ${Device.laptopM} {
        width: 100%;
    }

    @media ${Device.laptopL} {
        width: 100%;
    }
`;
const NutrientsList = styled.div`
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    border-top: 1px solid lightgray;
    padding: .5rem;
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    font-size: 0.75rem;
    text-transform: uppercase;
    text-align: center;
`;
const NutrientItemName = styled.span`
    font-size: 0.5rem;
`;
const Image = styled.img`
    object-fit: cover;
    width: 100%;

    /* @media ${Device.mobileS} {
        width: 100%;
        //height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    } */
`;
const Title = styled.span`
    padding: .5rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
function Card(props) {
    const element = props.data;
    const [calorie, setCalorie] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbs, setCarbs] = useState(0);

    useEffect(() => {
        if (element.product !== null) {
            setCalorie(element.product.calorie/100*element.weight);
            setProtein(element.product.protein/100*element.weight);
            setFat(element.product.fat/100*element.weight);
            setCarbs(element.product.carbs/100*element.weight);
        }

        if (element.recipe !== null) {
            let sumCalorie = 0;
            let sumProtein = 0;
            let sumFat = 0;
            let sumCarbs = 0;

            element.recipe.products.forEach(p => {
                sumCalorie += p.product.calorie/100*p.weight;
                sumProtein += p.product.protein/100*p.weight;
                sumFat += p.product.fat/100*p.weight;
                sumCarbs += p.product.carbs/100*p.weight;
            })
            setCalorie(sumCalorie/element.recipe.portion_count);
            setProtein(sumProtein/element.recipe.portion_count);
            setFat(sumFat/element.recipe.portion_count);
            setCarbs(sumCarbs/element.recipe.portion_count);
        }
    }, [element])

    return <Component>
        {/* {element.product !== null && <Cover><CoverImage width='auto' height='auto' image={null}/></Cover>} */}
        
        {element.product === null && <Image  src={element.recipe.image.path}/>}
        <Title>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</Title>
        <NutrientsList>
            <NutrientItem><b>{calorie}</b><NutrientItemName>Калории</NutrientItemName></NutrientItem>
            <NutrientItem><b>{protein}</b><NutrientItemName>Белки</NutrientItemName></NutrientItem>
            <NutrientItem><b>{fat}</b><NutrientItemName>Жиры</NutrientItemName></NutrientItem>
            <NutrientItem><b>{carbs}</b><NutrientItemName>Углеводы</NutrientItemName></NutrientItem>
        </NutrientsList>
    </Component>
}

export default Card;