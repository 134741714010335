import { observe } from "mobx";
import { inject, observer } from "mobx-react";
import styled, {keyframes} from "styled-components";
import { Colors } from "../constants";
import Input from "../components/input_material";
import Button from "../components/button";
import { useState } from "react";
import {toast} from 'react-toastify'

const gradient = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
const Component = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    animation: ${gradient} 10s ease infinite;
    background: linear-gradient(-45deg, ${Colors.green}, ${Colors.peach}, ${Colors.light_green});
    background-size: 400% 400%;
`;
const AuthForm = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 3rem;
    padding-top: 1rem;
    border: 1px solid lightgray;
    border-radius: .5rem;
    background-color: white;
`;
const Title = styled.span`
    font-size: 2rem;
    font-family: 'Amatic SC', cursive;
    text-align: center;
    width: 100%;
    padding-bottom: 1rem;
`;
const GradientTextButton = styled.a`
	user-select: none;

	&:hover {
		background: linear-gradient(-45deg, ${Colors.green}, ${Colors.peach});
    	-webkit-background-clip: text;
    	-webkit-text-fill-color: transparent;
		cursor: pointer;
	}
`;

const fieldIds = {
    login: 'login',
    password: 'password',
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name'
}

function Auth(props) {
    const [state, setState] = useState(false);

    const [userLogin, setUserLogin] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');


    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.login:
                setUserLogin(event.target.value);
                break;
            case fieldIds.password:
                setUserPassword(event.target.value);
                break;
            case fieldIds.email:
                setUserEmail(event.target.value);
                break;
            case fieldIds.first_name:
                setUserFirstName(event.target.value);
                break;
            case fieldIds.last_name:
                setUserLastName(event.target.value);
                break;
            default:
                break;
        }
    }

    function sign() {
        if (userLogin === null || userLogin === '') {
			toast.error('Логин не может быть пустым.');
			return;
		}

		if (userPassword === null || userPassword === '') {
			toast.error('Пароль не может быть пустым.');
			return;
		}

		if (state) {
			if (userEmail === null || userEmail === '') {
				toast.error('E-mail не может быть пустым.');
				return;
			}

			if (userFirstName === null || userFirstName === '') {
				toast.error('Имя не может быть пустым.');
				return;
			}

			if (userLastName === null || userLastName === '') {
				toast.error('Фамилия не может быть пустой.');
				return;
			}
		}

        const data = {
			user_name: userLogin,
			password: userPassword,
			email: userEmail,
			first_name: userFirstName,
			last_name: userLastName
		};

        if (state) {
            props.store.User.signUp(data);
        }
        else {
            props.store.User.signIn(data);
        }
    }

    return <Component>
        <AuthForm>
            <Title>{state ? 'Регистрация' : 'Авторизация'}</Title>
            <Input type='text' id={fieldIds.login} label='Логин' onChange={(event) => onChange(event)}/>
            <Input type='password' id={fieldIds.password} label='Пароль' onChange={(event) => onChange(event)}/>
            { state && <Input type='text' id={fieldIds.email} label='E-mail' onChange={(event) => onChange(event)}/>}
            { state && <Input type='text' id={fieldIds.first_name} label='Имя' onChange={(event) => onChange(event)}/>}
            { state && <Input type='text' id={fieldIds.last_name} label='Фамилия' onChange={(event) => onChange(event)}/>}
            <Button onClick={() => sign()}>{state ? 'Зарегистрироваться' : 'Войти'}</Button>
            <span>или все-таки <GradientTextButton onClick={() => setState(!state)}>{state ? 'авторизация' : 'регистрация'}?</GradientTextButton></span>
        </AuthForm>
    </Component>
}

export default inject("store")(observer(Auth));