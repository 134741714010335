import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../button";
import Input from "../input_material";
import dayjs from 'dayjs';
import Select from '../select';
import weekYear from 'dayjs/plugin/weekYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Meal from '../meal_item';
import Nutrients from "../nutrient";
import { Meals, Colors, Device } from "../../constants";
import { inject } from "mobx-react";
import { toast } from "react-toastify";
import { toJS } from "mobx";
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const Component = styled.div`
    position: relative;
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    min-width: 50vw;
    max-width: 100%;
    overflow-y: auto;
    max-height: 90vh;

    @media ${Device.mobileS} {
        gap: .5rem;
        max-width: 100%;
    }

    @media ${Device.mobileM} {
        gap: .5rem;
        max-width: 100%;
    }

    @media ${Device.mobileL} {
        gap: .75rem;
        max-width: 100%;
    }

    @media ${Device.tablet} {
        gap: 1rem;
        max-width: 100%;
    }

    @media ${Device.laptopS} {
        gap: 1rem;
        max-width: 70vw;
    }

    @media ${Device.laptopM} {
        gap: .75rem;
        max-width: 60vw;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        max-width: 50vw;
    }
`;
const fieldIds = {
    menu: {
        name: 'menuName',
        week: 'menuWeek',
        date: 'menuDate'
    },
    item: {
        meal: 'itemMeal',
        product_id: 'itemProductId',
        recipe_id: 'itemRecipeId',
        weight: 'itemWeight'
    }
}
const InputName = styled.div`
    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }

    @media ${Device.laptopS} {
        width: 100%;
    }

    @media ${Device.laptopM} {
        width: 100%;
    }

    @media ${Device.laptopL} {
        width: 100%;
    }
`;
const Title = styled.span`
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.6rem;
    }
`;

const SmallText = styled.span`
    font-size: smaller;
    text-align: center;
`;
const Columns = styled.div`
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    display: flex;
    width: 20vw;

    @media ${Device.mobileS} {
        width: 100%;
        gap: .75rem;
    }

    @media ${Device.mobileM} {
        width: 80vw;
        gap: .75rem;
    }

    @media ${Device.mobileL} {
        width: 75vw;
        gap: .75rem;
    }

    @media ${Device.tablet} {
        gap: .75rem;
    }

    @media ${Device.laptopS} {
        gap: .75rem;
    }

    @media ${Device.laptopM} {
        gap: .75rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const Rows = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    display: flex;

    @media ${Device.mobileS} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;
const MenuItems = styled.div`
    flex-direction: column;
    display: flex;
    border: 1px solid lightgray;
    border-radius: .5rem;
    overflow: hidden;
`;
const NutrientSection = styled.div`
    justify-content: center;
    text-align: center;
    display: flex;
    width: 100%;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }

    @media ${Device.laptopS} {
        width: 100%;
    }

    @media ${Device.laptopM} {
        width: 100%;
    }

    @media ${Device.laptopL} {
        width: 100%;
    }
`;
const Centered = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    gap: 1rem;
`;

function Modal(props) {
    const storeProducts = toJS(props.store.Products.products);
    const storeRecipes = toJS(props.store.Recipes.recipes);
    const [menuName, setMenuName] = useState('');
    const [menuDate, setMenuDate] = useState('');
    const [menuWeek, setMenuWeek] = useState('');
    const [itemMeal, setItemMeal] = useState('');
    const [itemProductId, setItemProductId] = useState('');
    const [itemRecipeId, setItemRecipeId] = useState('');
    const [itemWeight, setItemWeight] = useState('');
    const [items, setItems] = useState([]);
    const [finishItems, setFinishItems] = useState(false);
    const [mealIndex, setMealIndex] = useState(0);
    const [itemIndex, setItemIndex] = useState(null);
    const [calorie, setCalorie] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbs, setCarbs] = useState(0);

    useEffect(() => {   
        let sumTotalCalorie = 0;
        let sumTotalProtein = 0;
        let sumTotalFat = 0;
        let sumTotalCarbs = 0;

        items.forEach(item => {
            if (item.product !== null) {
                sumTotalCalorie += item.product.calorie/100*item.weight;
                sumTotalProtein += item.product.protein/100*item.weight;
                sumTotalFat += item.product.fat/100*item.weight;
                sumTotalCarbs += item.product.carbs/100*item.weight;
            }
    
            if (item.recipe !== null) {
                let sumCalorie = 0;
                let sumProtein = 0;
                let sumFat = 0;
                let sumCarbs = 0;
    
                item.recipe.products.forEach(p => {
                    sumCalorie += p.product.calorie/100*p.weight;
                    sumProtein += p.product.protein/100*p.weight;
                    sumFat += p.product.fat/100*p.weight;
                    sumCarbs += p.product.carbs/100*p.weight;
                })
                sumTotalCalorie += sumCalorie/item.recipe.portion_count;
                sumTotalProtein += sumProtein/item.recipe.portion_count;
                sumTotalFat += sumFat/item.recipe.portion_count;
                sumTotalCarbs += sumCarbs/item.recipe.portion_count;
            }
        })

        setCalorie(sumTotalCalorie);
            setProtein(sumTotalProtein);
            setFat(sumTotalFat);
            setCarbs(sumTotalCarbs);

    }, [items])

    useEffect(() => {
        if (finishItems) {
            props.store.Menus.loadingData();
            props.store.MenuItems.loadingData();
            props.store.Global.setModal()
        }
    }, [finishItems])

    useEffect(() => {
        setMenuDate(props.store.Global.modalData.weekday);
    }, [])

    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.menu.name:
                setMenuName(event.target.value);
                break;
            case fieldIds.menu.week:
                setMenuWeek(event.target.value);
                break;
            case fieldIds.menu.date:
                setMenuDate(event.target.value);
                break;
            case fieldIds.item.meal:
                setItemMeal(event.target.value);
                break;
            case fieldIds.item.product_id:
                setItemProductId(event.target.value);
                break;
            case fieldIds.item.recipe_id:
                setItemRecipeId(event.target.value);
                break;
            case fieldIds.item.weight:
                setItemWeight(event.target.value);
                break;
            default:
                break;
        }
    }

    function addMeal() {
        if (itemMeal === '') {
            toast.error('Не выбран прием пищи');
            return;
        }

        if (itemProductId === '' && itemRecipeId === '') {
            toast.error('Не выбран продукт/рецепт');
            return;
        }

        if (itemWeight === '' || parseInt(itemWeight) === 0) {
            toast.error('Не указан вес');
            return;
        }

        let product = null; 
        if (itemProductId !== '') {
            product = storeProducts.find(p => p.id === itemProductId);
        }

        let recipe = null;
        if (itemRecipeId !== '') {
            recipe = storeRecipes.find(r => r.id === itemRecipeId);
        }

        if (product === undefined) {
            toast.error('Не удалось найти продукт');
            return;
        }

        if (recipe === undefined) {
            toast.error('Не удалось найти рецепт');
            return;
        }

        const mealData = {
            index: itemIndex !== null ? itemIndex : mealIndex,
            item_type: itemMeal,
            product_id: itemProductId,
            recipe_id: itemRecipeId,
            weight: itemWeight,
            product: product,
            recipe: recipe
        }

        const currentItems = [...items];
        if (itemIndex !== null) {
            let index = currentItems.findIndex(i => i.index === itemIndex);
            if (index !== -1) {
                currentItems[index] = mealData;
                setItems(currentItems);
                clear();
            }
        }
        else {
            currentItems.push(mealData);
            setItems(currentItems);
            setMealIndex(mealIndex + 1);
            clear();
        }
        
    }

    function create() {
        if (items.length === 0) {
            toast.error('Не был добавлен хотя бы один продукт/рецепт');
            return;
        }

        const menuData = {
            name: menuName,
            menu_date: menuDate
        }

        props.store.Menus.create(menuData)
        .then(result => {
            if (result.data.status) {
                const id = result.data.data.id;
                let itemsData = [];
                items.forEach((item) => {
                    const itemData = {
                        menu_id: id,
                        item_type: item.item_type,
                        product_id: item.product_id,
                        recipe_id: item.recipe_id,
                        weight: item.weight
                    }
                    itemsData.push(itemData);
                })
                props.store.MenuItems.create({items: itemsData})
                .then(res => {
                    if (res.data.status) {
                        setFinishItems(true);
                    }
                })
                .catch(err => {
                    toast.error('Меню не было создано');
                    return;
                })
            }
            else {
                toast.error('Меню не было создано');
                return;
            }
        })
        .catch(error => {
            toast.error('Меню не было создано');
        })
    }

    function clear() {
        setItemMeal('');
        setItemProductId('');
        setItemRecipeId('');
        setItemWeight('');
        setItemIndex(null);
    }

    function deleteMealElement(element) {
        let itms = [...items];
        itms = itms.filter(i => i !== element);
        setItems(itms);
    }

    function setEditedElement(element) {
        setItemIndex(element.index);
        setItemMeal(element.item_type);
        setItemProductId(element.product_id);
        setItemRecipeId(element.recipe_id);
        setItemWeight(element.weight);
    }

    return <Component>
        <Centered>
            <Title>{new Date(menuDate).toLocaleDateString()}</Title>
        </Centered>
        <InputName>
            <Input type='text' id={fieldIds.menu.name} value={menuName} label='Название' onChange={(event => onChange(event))}/>
        </InputName>
        <Rows>
            <Columns>
                <Title>{itemIndex !== null ? 'Изменить' : 'Добавить'}</Title>
                <Select id={fieldIds.item.meal} value={itemMeal} onChange={(event => onChange(event))}>
                    <option value={''} hidden>Выбрать прием пищи</option>
                    <option value={Meals.day_meal.name}>{Meals.day_meal.label}</option>
                    <option value={Meals.breakfast.name}>{Meals.breakfast.label}</option>
                    <option value={Meals.lunch.name}>{Meals.lunch.label}</option>
                    <option value={Meals.dinner.name}>{Meals.dinner.label}</option>
                    <option value={Meals.snack.name}>{Meals.snack.label}</option>
                </Select>
                <Select id={fieldIds.item.product_id} disabled={itemRecipeId !== ''} value={itemProductId} onChange={(event) => onChange(event)}>
                    <option key={0} value={''} hidden>Выбрать продукт</option>
                {
                    storeProducts.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                }
                </Select>
                <SmallText>или</SmallText>
                <Select id={fieldIds.item.recipe_id} disabled={itemProductId !== ''} value={itemRecipeId} onChange={(event) => onChange(event)}>
                    <option key={0} value={''} hidden>Выбрать рецепт</option>
                {
                    storeRecipes.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                }
                </Select>
                <Input type='number' id={fieldIds.item.weight} value={itemWeight} label='Вес, г.' onChange={(event => onChange(event))}/>
                <Centered>
                    <Button onClick={() => addMeal()}>{itemIndex !== null ? 'Сохранить' : 'Добавить'}</Button>
                    <Button onClick={() => clear()}>Очистить</Button>
                </Centered>
            </Columns>
            <Columns>
                <Title>Состав меню</Title>
                <MenuItems>
                    <Meal key={Meals.day_meal.name} label={Meals.day_meal.label} items={items.filter(i => i.item_type === Meals.day_meal.name)} onClick={setEditedElement} onDelete={deleteMealElement} />
                    <Meal key={Meals.breakfast.name} label={Meals.breakfast.label} items={items.filter(i => i.item_type === Meals.breakfast.name)} onClick={setEditedElement} onDelete={deleteMealElement} />
                    <Meal key={Meals.lunch.name} label={Meals.lunch.label} items={items.filter(i => i.item_type === Meals.lunch.name)} onClick={setEditedElement} onDelete={deleteMealElement} />
                    <Meal key={Meals.dinner.name} label={Meals.dinner.label} items={items.filter(i => i.item_type === Meals.dinner.name)} onClick={setEditedElement} onDelete={deleteMealElement} />
                    <Meal key={Meals.snack.name} label={Meals.snack.label} items={items.filter(i => i.item_type === Meals.snack.name)} onClick={setEditedElement} onDelete={deleteMealElement} />
                </MenuItems>
            </Columns>
        </Rows>
        <NutrientSection>
            <Nutrients calorie={calorie} protein={protein} fat={fat} carbs={carbs} />
        </NutrientSection>
        <Centered>
            <Button onClick={() => create()}>Создать меню</Button>
        </Centered>
    </Component>
}

export default inject("store")(Modal);