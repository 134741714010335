import { makeObservable, observable, action } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Images {
    images = []

    constructor(title) { 
        makeObservable(this, {
            images: observable,
            loadingData: action,
            create: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'paprikman';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    loadingData() {
        axios.get(Config.api.host.paprikman[NODE_ENV]+Config.api.images.get_all)
        .then(result => {
            if (result.data.status) {
                this.images = result.data.data;
            }
            else {
                toast.error(result.data.message)
            }
        })
        .catch(error => {
            toast.error(error)
        })
    }

    create(data) {
        return axios.post(Config.api.host.paprikman[NODE_ENV]+Config.api.images.create, data);
    }
}

export default new Images();