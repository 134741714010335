import styled from "styled-components";
import Button from "./button";
import { ReactComponent as IconDelete } from "../icons/delete.svg";
import { Colors, Meals, Device } from "../constants";

//#region Styles
const Component = styled.div`
    flex-direction: column;
    display: flex;
`;
const MealTitle = styled.span`
    padding: .25rem;
    background-color: ${Colors.peach};
    color: white;
    text-align: center;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const MealItem = styled.span`
    padding: .25rem .5rem;

    @media ${Device.mobileS} {
        font-size: 0.9rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.9rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.9rem;
    }

    @media ${Device.tablet} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const MealElement = styled.div`
    flex-direction: row;
    gap: .25rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
`;
const Empty = styled.span`
    padding: .25rem;
    text-align: center;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.3rem;
    }
`;
const DeleteButton = styled(IconDelete)`
    fill: ${Colors.red};
    height: 20px;
    width: 20px;
`;
//#endregion

export default function MealComponent(props) {
    return <Component>
        <MealTitle>{props.label}</MealTitle>
        {
            props.items.map((element, index) => <MealItem key={index}>
                <MealElement>
                    <span onClick={() => props.onClick(element)}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</span>
                    <DeleteButton onClick={() => props.onDelete(element)}>Удалить</DeleteButton>
                </MealElement>
            </MealItem>)
        }
        {
            props.items.length === 0 && <Empty>Пусто</Empty>
        }
    </Component>
}