import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import styled from 'styled-components';
import Main from './pages/main';
import Header from './components/header';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import * as vars from './constants';
import Products from './pages/products';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './components/modal';
import Recipes from './pages/recipes';
import Menus from './pages/menus';
import Recipe from './pages/recipe';
import Auth from './pages/auth';

const Page = styled.div`
	flex-direction: column;
	display: flex;
	//background-color: ${vars.Colors.light_green};
	background: linear-gradient(130deg, #D5FFD5, #A7FFE3, #D5FFD5);
	height: 100%;
`;

function App(props) {
	if (!props.store.User.is_signin) {
		return <>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				limit={10}/>
			<Auth />
		</>
	}

	return (
		<Page>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				limit={10}/>
			<Modal {...props}/>
			<Header {...props}/>
			<Routes>
				<Route path='/' element={<Main/>}/>
				<Route path='/menus' element={<Menus/>}/>
				<Route path='/recipe/:id' element={<Recipe />}/>
				<Route path='/recipes' element={<Recipes/>}/>
				<Route path='/products' element={<Products/>}/>
				
			</Routes>
		</Page>
	);
}

export default inject("store")(observer(App));
