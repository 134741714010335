import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "mobx-react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components';
import Global from './store/global';
import Products from './store/products';
import Recipes from './store/recipes';
import Images from './store/images';
import RecipeSteps from './store/recipe_steps';
import RecipeProducts from './store/recipe_products';
import { configure } from "mobx"
import Menus from './store/menus';
import MenuItems from './store/menu_items';
import User from './store/user';
// configure({
//     useProxies: "never"
// })

const GlobalStyle = createGlobalStyle`
	html {
		height: 100%;
	}
	body {
		height: 100%;
		margin: 0;
		font-family: 'Roboto', sans-serif;

		*, ::before, ::after {
			box-sizing: border-box;
		}
	}
	div#root {
		height: 100%;
	}
`;
const Page = styled.div`
	height: 100%;
`;
const stores = {
	Global,
	Products,
	Recipes,
	Images,
	RecipeSteps,
	RecipeProducts,
	Menus,
	MenuItems,
	User
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={stores}>
		<Router>
			<GlobalStyle />
			<App/>
		</Router>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
