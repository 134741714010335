import styled from "styled-components";

const Component = styled.label`
    text-transform: uppercase;
    font-family: 'Amatic SC', cursive;
    font-size: 1.5rem;
`;

export default function Label(props) {
    return <Component {...props}>{props.children}</Component>
}