import { makeObservable, observable, action } from "mobx";
import axios from 'axios';
import { getToken, removeToken, setToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';
import images from "./images";
import menu_items from "./menu_items";
import menus from "./menus";
import products from "./products";
import recipe_products from "./recipe_products";
import recipe_steps from "./recipe_steps";
import recipe from "./recipes";

const NODE_ENV = process.env.NODE_ENV || 'production';
class User {
    user = {};
    is_signin = false;

    constructor(title) { 
        makeObservable(this, {
            user: observable,
            is_signin: observable,
            loadingData: action,
            signIn: action,
            signUp: action,
            logout: action,
        })

        this.loadingData = this.loadingData.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.logout = this.logout.bind(this);

        this.loadingData();
    }

    loadingData() {
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'paprikman';

        this.token = getToken();
        
        if (this.token !== '') {
            axios.defaults.headers.common['x-access-token'] = this.token;
            axios.get(Config.api.host.common[NODE_ENV] + Config.api.main.get)
            .then(result => {
                if (result.data.status) {
                    this.user = result.data.data;
                    this.is_signin = true;

                    images.loadingData();
                    menu_items.loadingData();
                    menus.loadingData();
                    products.loadingData();
                    recipe_products.loadingData();
                    recipe_steps.loadingData();
                    recipe.loadingData();
                }
                else {
                    toast.error(result.data.text);
                    removeToken();
                }
            })
            .catch(error => {
                toast.error(error.response.data.text);
            })
        }
    }

    signIn(_data) {
        const data = {
            user_name: _data.user_name,
            password: _data.password
        };
        axios.post(Config.api.host.common[NODE_ENV] + Config.api.main.sign_in, data)
        .then(result => {
            if (result.data.status) {
                setToken(result.data.access_token);
                this.loadingData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    signUp(_data) {
        const data = {
            user_name: _data.user_name,
            password: _data.password,
            email: _data.email,
            first_name: _data.first_name,
            last_name: _data.last_name
        };
        axios.post(Config.api.host.common[NODE_ENV] + Config.api.main.sign_up, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.signIn(_data);
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    logout() {
        this.token = '';
        this.is_signin = false;
        removeToken();
    }
}

export default new User();