import CreateProduct from './create_product';
import CreateRecipe from './create_recipe';
import CreateMenu from './create_menu';
import EditProduct from './edit_product';
import EditRecipe from './edit_recipe';
import EditMenu from './edit_menu';
import { Modals } from '../../constants';

export default function Component(props) {
    const type = props.store.Global.modalType;
    const data = props.store.Global.modalData;

    switch(type) {
        case Modals.create_product.name:
            return <CreateProduct {...props}/>;
        case Modals.create_recipe.name:
            return <CreateRecipe {...props}/>;
        case Modals.create_menu.name:
            return <CreateMenu {...props}/>;
        case Modals.edit_product.name:
            return <EditProduct {...props} data={data}/>;
        case Modals.edit_recipe.name:
            return <EditRecipe {...props} data={data}/>;
        case Modals.edit_menu.name:
            return <EditMenu {...props} data={data}/>;
        default:
            return null;
    }
}