import styled from 'styled-components';
import { observer } from "mobx-react";
import Menu from './menu';
import Avatar from './avatar';
import { ReactComponent as LogoFile } from "../icons/vectorpaint.svg";
import { Device } from '../constants';
const Component = styled.div`
    height: 100px;
    width: 100%;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;

    @media ${Device.mobileS} {
        height: 75px;
    }

    @media ${Device.mobileM} {
        height: 75px;
    }

    @media ${Device.mobileL} {
        height: 75px;
    }

    @media ${Device.tablet} {
        height: 75px;
    }

    @media ${Device.laptopS} {
        height: 75px;
    }

    @media ${Device.laptopM} {
        height: 75px;
    }

    @media ${Device.laptopL} {
        height: 75px;
    }
`;
const HeaderSection = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    display: flex;
    padding: 0 2rem;
    height: 100px;
    width: 100%;
    background-color: white;

    @media ${Device.mobileS} {
        gap: 0.5rem;
        padding: 0 1rem;
        height: 75px;
    }

    @media ${Device.mobileM} {
        gap: 0.5rem;
        padding: 0 1rem;
        height: 75px;
    }

    @media ${Device.mobileL} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 75px;
    }

    @media ${Device.tablet} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 75px;
    }

    @media ${Device.laptopS} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 75px;
    }

    @media ${Device.laptopM} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 75px;
    }

    @media ${Device.laptopL} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 75px;
    }
`;
const Logo = styled(LogoFile)`

    @media ${Device.mobileS} {
        width: 100px;
    }

    @media ${Device.mobileM} {
        width: 125px;
    }

    @media ${Device.mobileL} {
        width: 125px;
    }

    @media ${Device.tablet} {
        width: 150px;
    }

    @media ${Device.laptopS} {
        width: 150px;
    }

    @media ${Device.laptopM} {
        width: 150px;
    }

    @media ${Device.laptopL} {
        width: 150px;
    }
`;
function Header(props) {
    return <Component>
        <HeaderSection>
        <Logo />
        <Menu />
        <Avatar {...props}/>
        </HeaderSection>
    </Component>
}

export default observer(Header);