import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../button";
import Input from "../input_material";
import dayjs from 'dayjs';
import Select from '../select';
import weekYear from 'dayjs/plugin/weekYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Meals, Colors, Device } from "../../constants";
import { inject } from "mobx-react";
import { toast } from "react-toastify";
import { toJS } from "mobx";
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    min-width: 60vw;
    max-width: 90vw;
    overflow-y: auto;
    max-height: 90vh;
`;
const fieldIds = {
    menu: {
        name: 'menuName',
        week: 'menuWeek',
        date: 'menuDate'
    },
    item: {
        meal: 'itemMeal',
        product_id: 'itemProductId',
        recipe_id: 'itemRecipeId',
        weight: 'itemWeight'
    }
}
const Title = styled.span`
    font-size: 1.6rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.25rem;
    }

    @media ${Device.tablet} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.6rem;
    }
`;
const Empty = styled.span`
    padding: .25rem;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 1.3rem;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.3rem;
    }
`;
const SmallText = styled.span`
    font-size: smaller;
    text-align: center;
`;
const Columns = styled.div`
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    display: flex;
    width: 20vw;

    @media ${Device.mobileS} {
        width: 80vw;
        gap: .75rem;
    }

    @media ${Device.mobileM} {
        width: 80vw;
        gap: .75rem;
    }

    @media ${Device.mobileL} {
        width: 80vw;
        gap: .75rem;
    }

    @media ${Device.tablet} {
        gap: .75rem;
    }

    @media ${Device.laptopS} {
        gap: .75rem;
    }

    @media ${Device.laptopM} {
        gap: .75rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const Rows = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    
    display: flex;

    @media ${Device.mobileS} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
        width: 100%;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;

const HeadRows = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    display: flex;

    @media ${Device.mobileS} {
        width: 80%;
    }


    @media ${Device.mobileM} {
        width: 80%;
    }


    @media ${Device.mobileL} {
        width: 80%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }
`;
const Line = styled.div`
    content: ' ';
    width: 100%;
    border: 1px solid lightgray;
`;
const MenuItems = styled.div`
    flex-direction: column;
    display: flex;
    border: 1px solid lightgray;
    border-radius: .5rem;
    overflow: hidden;
`;
const Meal= styled.div`
    flex-direction: column;
    display: flex;
`;
const MealTitle = styled.span`
    padding: .25rem;
    background-color: ${Colors.peach};
    color: white;
    font-size: 1.2rem;
    text-align: center;

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const MealItem = styled.span`
    padding: .25rem .5rem;

    @media ${Device.mobileS} {
        font-size: 0.9rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.9rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.9rem;
    }

    @media ${Device.tablet} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const NutrientSection = styled.div`
    justify-content: center;
    text-align: center;
    display: flex;
    width: 100%;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }

    @media ${Device.laptopS} {
        width: 100%;
    }

    @media ${Device.laptopM} {
        width: 100%;
    }

    @media ${Device.laptopL} {
        width: 100%;
    }
`;
const NutrientTotal = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    width: 100%;
    background-color: white;
    font-size: 1.2rem;
    overflow: hidden;

    @media ${Device.mobileS} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
        width: 100%;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
        width: 100%;
    }
`;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    padding: .5rem;
    max-width: 50%;
    min-width: 30%;
    text-align: center;

    @media ${Device.mobileL} {
        min-width: 30%;
    }

    @media ${Device.tablet} {
        min-width: 20%;
    }

    @media ${Device.laptopS} {
        min-width: 20%;
    }

    @media ${Device.laptopM} {
        min-width: 20%;
    }

    @media ${Device.laptopL} {
        min-width: 20%;
    }
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.mobileS} {
        font-size: 0.8rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.8rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.8rem;
    }

    @media ${Device.tablet} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.5rem;
    color: ${props => props.color};

    @media ${Device.mobileS} {
        font-size: 1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const MealElement = styled.div`
    flex-direction: row;
    gap: .25rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
`;
const Centered = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
`;

function Modal(props) {
    const storeProducts = toJS(props.store.Products.products);
    const storeRecipes = toJS(props.store.Recipes.recipes);
    const [menuName, setMenuName] = useState('');
    const [menuWeek, setMenuWeek] = useState('');
    const [menuDate, setMenuDate] = useState('');
    const [menuId, setMenuId] = useState('');
    const [itemMeal, setItemMeal] = useState('');
    const [itemProductId, setItemProductId] = useState('');
    const [itemRecipeId, setItemRecipeId] = useState('');
    const [itemWeight, setItemWeight] = useState('');
    const [itemId, setItemId] = useState('');
    const [items, setItems] = useState([]);
    const [menus, setMenus] = useState([]);
    const [finishItems, setFinishItems] = useState(false);
    const [finishSteps, setFinishSteps] = useState(false);

    const [menuEdited, setMenuEdited] = useState(false);

    const [calorie, setCalorie] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbs, setCarbs] = useState(0);

    useEffect(() => {   
        let sumTotalCalorie = 0;
        let sumTotalProtein = 0;
        let sumTotalFat = 0;
        let sumTotalCarbs = 0;

        items.forEach(item => {

            if (item.product !== null) {
                sumTotalCalorie += item.product.calorie/100*item.weight;
                sumTotalProtein += item.product.protein/100*item.weight;
                sumTotalFat += item.product.fat/100*item.weight;
                sumTotalCarbs += item.product.carbs/100*item.weight;
            }
    
            if (item.recipe !== null) {
                let sumCalorie = 0;
                let sumProtein = 0;
                let sumFat = 0;
                let sumCarbs = 0;
    
                item.recipe.products.forEach(p => {
                    sumCalorie += p.product.calorie/100*p.weight;
                    sumProtein += p.product.protein/100*p.weight;
                    sumFat += p.product.fat/100*p.weight;
                    sumCarbs += p.product.carbs/100*p.weight;
                })
                sumTotalCalorie += sumCalorie/item.recipe.portion_count;
                sumTotalProtein += sumProtein/item.recipe.portion_count;
                sumTotalFat += sumFat/item.recipe.portion_count;
                sumTotalCarbs += sumCarbs/item.recipe.portion_count;
            }
        })

        setCalorie(sumTotalCalorie);
            setProtein(sumTotalProtein);
            setFat(sumTotalFat);
            setCarbs(sumTotalCarbs);

    }, [items])

    useEffect(() => {
        if (finishItems) {
            props.store.Menus.loadingData();
            props.store.MenuItems.loadingData();
            props.store.Global.setModal()
        }
    }, [finishItems])

    useEffect(() => {
        const data = props.store.Global.modalData.data;
        setMenuDate(props.store.Global.modalData.weekday);
        setMenuName(data.name);
        setMenuId(data.id);
        setItems(data.items);
    }, [])

    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.menu.name:
                setMenuName(event.target.value);
                setMenuEdited(true);
                break;
            case fieldIds.menu.week:
                setMenuWeek(event.target.value);
                setMenuEdited(true);
                break;
            case fieldIds.menu.date:
                setMenuDate(event.target.value);
                setMenuEdited(true);
                break;
            case fieldIds.item.meal:
                setItemMeal(event.target.value);
                break;
            case fieldIds.item.product_id:
                setItemProductId(event.target.value);
                break;
            case fieldIds.item.recipe_id:
                setItemRecipeId(event.target.value);
                break;
            case fieldIds.item.weight:
                setItemWeight(event.target.value);
                break;
            default:
                break;
        }
    }

    function addMeal() {
        let product = null; 
        let recipe = null;
        if (itemProductId !== '')
            product = storeProducts.find(p => p.id === itemProductId);
        if (itemRecipeId !== '')
            recipe = storeRecipes.find(r => r.id === itemRecipeId);

        let mealData = {
            menu_id: menuId,
            item_type: itemMeal,
            product_id: itemProductId,
            recipe_id: itemRecipeId,
            weight: itemWeight,
            product: product,
            recipe: recipe
        }

        const currentItems = [...items];

        if (itemId !== '') {
            mealData.id = itemId;
            props.store.MenuItems.update(mealData)
            .then(res => {
                if (res.data.status) {
                    props.store.MenuItems.loadingData();
                    let index = currentItems.findIndex(i => i.id === mealData.id);
                    if (index !== -1) {
                        currentItems[index] = mealData;
                        setItems(currentItems);
                        
                        setItemMeal('');
                        setItemProductId('');
                        setItemRecipeId('');
                        setItemWeight('');
                        setItemId('');
                    }
                    
                }
            })
            .catch(err => {
                toast.error('Меню не было создано');
                return;
            })
        }
        else {
            props.store.MenuItems.create({items: [mealData]})
            .then(res => {
                if (res.data.status) {
                    mealData.id = res.data.data[0].id;
                    props.store.MenuItems.loadingData();
                    currentItems.push(mealData);
                    setItems(currentItems);
                    
                    setItemMeal('');
                    setItemProductId('');
                    setItemRecipeId('');
                    setItemWeight('');
                    setItemId('');
                }
            })
            .catch(err => {
                toast.error('Меню не было создано');
                return;
            })
        }

        
    }

    function update() {
        const menuData = {
            name: menuName,
            menu_date: menuDate
        }

        props.store.Menus.update(menuData)
        .then(result => {
            if (result.data.status) {
                setFinishItems(true);
            }
            else {
                toast.error('Меню не было обновлено');
                return;
            }
        })
        .catch(error => {
            toast.error('Меню не было обновлено');
        })
    }

    function clear() {
        setItemMeal('');
        setItemProductId('');
        setItemRecipeId('');
        setItemWeight('');
        setItemId('');
    }

    function deleteMealElement(element) {
        let itms = [...items];
        itms = itms.filter(i => i !== element);

        props.store.MenuItems.delete(element.id)
        .then(result => {
            if (result.data.status) {
                setItems(itms);
            }
            else {
                toast.error('При удалении возникла ошибка')
            }
        })
        .catch(error => {
            toast.error('При удалении возникла ошибка')
        })
        
    }

    function setEditedElement(element) {
        setItemMeal(element.item_type);
        setItemProductId(element.product_id);
        setItemRecipeId(element.recipe_id);
        setItemWeight(element.weight);
        setItemId(element.id)
    }

    return <Component>
        <Centered>
        <Title>{new Date(menuDate).toLocaleDateString()}</Title>
        </Centered>
        <Columns>
            <Input type='text' id={fieldIds.menu.name} value={menuName} label='Название' onChange={(event => onChange(event))}/>
            </Columns>
        <Rows>
        <Columns>
        <Title>Добавить</Title>
        <Select id={fieldIds.item.meal} value={itemMeal} onChange={(event => onChange(event))}>
            <option value={''} hidden>Выбрать прием пищи</option>
            <option value={Meals.breakfast.name}>{Meals.breakfast.label}</option>
            <option value={Meals.lunch.name}>{Meals.lunch.label}</option>
            <option value={Meals.dinner.name}>{Meals.dinner.label}</option>
            <option value={Meals.snack.name}>{Meals.snack.label}</option>
        </Select>
        <Select id={fieldIds.item.product_id} disabled={itemRecipeId !== ''} value={itemProductId} onChange={(event) => onChange(event)}>
            <option key={0} value={''} hidden>Выберите продукт</option>
        {
            storeProducts.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
        }
        </Select>
        <SmallText>или</SmallText>
        <Select id={fieldIds.item.recipe_id} disabled={itemProductId !== ''} value={itemRecipeId} onChange={(event) => onChange(event)}>
            <option key={0} value={''} hidden>Выберите рецепт</option>
        {
            storeRecipes.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
        }
        </Select>
        <Input type='number' id={fieldIds.item.weight} value={itemWeight} label='Вес, г.' onChange={(event => onChange(event))}/>
        <Rows>
            <Button onClick={() => addMeal()}>{itemId !== '' ? 'Сохранить' : 'Добавить'}</Button>
            <Button onClick={() => clear()}>Очистить</Button>
        </Rows>
        </Columns>
            <Columns>
        <Title>Состав меню</Title>
        <MenuItems>
            <Meal>
                <MealTitle>{Meals.breakfast.label}</MealTitle>
                {
                    items.filter(i => i.item_type === Meals.breakfast.name).map(element => 
                        <MealItem>
                            <MealElement>
                                <span onClick={() => setEditedElement(element)}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</span>
                                <Button type='delete' onClick={() => deleteMealElement(element)}>Удалить</Button>
                            </MealElement>
                        </MealItem>
                    )
                }
                {
                    items.filter(i => i.item_type === Meals.breakfast.name).length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.lunch.label}</MealTitle>
                {
                    items.filter(i => i.item_type === Meals.lunch.name).map(element => 
                        <MealItem>
                            <MealElement>
                                <span onClick={() => setEditedElement(element)}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</span>
                                <Button type='delete' onClick={() => deleteMealElement(element)}>Удалить</Button>
                            </MealElement>
                        </MealItem>
                    )
                }
                {
                    items.filter(i => i.item_type === Meals.lunch.name).length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.dinner.label}</MealTitle>
                {
                    items.filter(i => i.item_type === Meals.dinner.name).map(element => 
                        <MealItem>
                            <MealElement>
                                <span onClick={() => setEditedElement(element)}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</span>
                                <Button type='delete' onClick={() => deleteMealElement(element)}>Удалить</Button>
                            </MealElement>
                        </MealItem>
                    )
                }
                {
                    items.filter(i => i.item_type === Meals.dinner.name).length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
            <Meal>
                <MealTitle>{Meals.snack.label}</MealTitle>
                {
                    items.filter(i => i.item_type === Meals.snack.name).map(element => 
                        <MealItem>
                            <MealElement>
                                <span onClick={() => setEditedElement(element)}>{element.product !== null && element.product.name}{element.recipe !== null && element.recipe.name}</span>
                                <Button type='delete' onClick={() => deleteMealElement(element)}>Удалить</Button>
                            </MealElement>
                        </MealItem>
                    )
                }
                {
                    items.filter(i => i.item_type === Meals.snack.name).length === 0 && <Empty>Пусто</Empty>
                }
            </Meal>
        </MenuItems>
        </Columns>
        
        </Rows>
        <NutrientSection>
            <NutrientTotal>
                <Title>Энергетическая ценность</Title>
                <NutrientsList>
                    <NutrientItem>
                        <NutrientTitle>Калории</NutrientTitle>
                        <NutrientValue color='#1e7390'>{calorie.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Белки</NutrientTitle>
                        <NutrientValue color='#07bc0c'>{protein.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Жиры</NutrientTitle>
                        <NutrientValue color={Colors.peach}>{fat.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                    <NutrientItem>
                        <NutrientTitle>Углеводы</NutrientTitle>
                        <NutrientValue color={Colors.red}>{carbs.toFixed(2)}</NutrientValue>
                    </NutrientItem>
                </NutrientsList> 
            </NutrientTotal>
        </NutrientSection>
        <HeadRows>
        { menuEdited && <Button onClick={() => update()}>Сохранить меню</Button> }
        </HeadRows>
    </Component>
}

export default inject("store")(Modal);