import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";
import { Colors, Device } from "../constants";
import Button from "../components/button";
import Step from "../components/step";
import CoverImage from "../components/cover_image";
import Nutrients from "../components/nutrient";

const Component = styled.div`
    width: 1200px;
    height: 100%;
    margin: 1rem auto;
    padding: 1rem;
    background-color: white;
    border-radius: .5rem;
    flex-direction: column;
    display: flex;
    gap: .5rem;
    overflow: auto;

    @media ${Device.mobileS} {
        width: calc(100% - 2rem);
        margin: 1rem;
        margin-top: 1.5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        width: calc(100% - 2rem);
        margin: 1rem;
        margin-top: 1.5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        width: calc(100% - 2rem);
        margin: 1rem;
        margin-top: 1.5rem;
        font-size: .9rem;
    }

    @media ${Device.tablet} {
        width: 700px;
        margin: 1rem auto;
        margin-top: 1rem;
        
    }

    @media ${Device.laptopS} {
        width: 800px;
        margin-top: 1rem;
    }

    @media ${Device.laptopM} {
        width: 900px;
        margin-top: 1rem;
    }

    @media ${Device.laptopL} {
        width: 1200px;
        margin-top: 1rem;
    }
`;
const Title = styled.span`
    font-size: 2rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.6rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.6rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.6rem;
    }

    @media ${Device.tablet} {
        font-size: 1.75rem;
    }

    @media ${Device.laptopS} {
        font-size: 2rem;
    }

    @media ${Device.laptopM} {
        font-size: 2rem;
    }

    @media ${Device.laptopL} {
        font-size: 2rem;
    }
`;
const LargeTitle = styled.span`
    font-size: 2.5rem;
    font-family: 'Amatic SC', cursive;
    width: 100%;
    text-align: center;

    @media ${Device.mobileS} {
        font-size: 2rem;
    }

    @media ${Device.mobileM} {
        font-size: 2rem;
    }

    @media ${Device.mobileL} {
        font-size: 2rem;
    }

    @media ${Device.tablet} {
        font-size: 2rem;
    }

    @media ${Device.laptopS} {
        font-size: 2rem;
    }

    @media ${Device.laptopM} {
        font-size: 2.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 2.5rem;
    }
`;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;
`;
const SmallText = styled.span`
    font-size: smaller;
`;
const ProductList = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
    width: 50%;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 50%;
        //margin: 0 auto;
    }

    @media ${Device.laptopM} {
        width: 40%;
    }

    @media ${Device.laptopL} {
        width: 30%;
    }
`;
const ProductItem = styled.div`
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    display: flex;
`;
const ProductItemLine = styled.div`
    content: ' ';
    border-bottom: 1px solid lightgray;
    flex: 1;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    max-height: 70vh;
`;
const Cover = styled.div`
    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        font-size: 1.1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.3rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const Columns = styled.div`
    flex-direction: column;
    display: flex;
`;
const Centered = styled.div`
    margin: 0 auto;
`;
function Page(props) {
    let { id } = useParams()
    const recipes = toJS(props.store.Recipes.recipes);
    
    const [recipe, setRecipe] = useState(null);
    const [init, setInit] = useState(false);
    const [recipeName, setRecipeName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');
    const [recipeIsPublic, setRecipeIsPublic] = useState(false);
    const [recipeExternalUrl, setRecipeExternalUrl] = useState('');
    const [recipeUrlName, setRecipeUrlName] = useState('');
    const [recipeCoverImageId, setRecipeCoverImageId] = useState('');
    const [recipePortionCount, setRecipePortionCount] = useState(1);
    const [recipeCarbs, setRecipeCarbs] = useState(0);
    const [recipeCalorie, setRecipeCalorie] = useState(0);
    const [recipeFat, setRecipeFat] = useState(0);
    const [recipeProtein, setRecipeProtein] = useState(0);
    const [recipeWeight, setRecipeWeight] = useState(0);
    const [recipeImage, setRecipeImage] = useState(null);
    const [recipeImagePreview, setRecipeImagePreview] = useState(null);
    const [stepName, setStepName] = useState('');
    const [stepDescription, setStepDescription] = useState('');
    const [stepOrder, setStepOrder] = useState(1);
    const [stepImage, setStepImage] = useState(null);
    const [stepImagePreview, setStepImagePreview] = useState(null);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [productWeight, setProductWeight] = useState(null);
    const [products, setProducts] = useState([]);
    const storeProducts = props.store.Products.products;
    const [finishProducts, setFinishProducts] = useState(false);
    const [finishSteps, setFinishSteps] = useState(false);
    const [isStepEdit, setStepEdit] = useState(false);
    const [stepIndex, setStepIndex] = useState(null);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [portionTitle, setPortionTitle] = useState('Порции');
    useEffect(() => {
        if (init) return;

        if (recipes !== undefined && recipes !== null) {
            if (recipes.length !== 0) {
                let sr = recipes.find(r => r.id === id);

                if (sr !== undefined && sr !== null) {
                    switch (parseInt((sr.portion_count+'').slice(-1))) {
                        case 0:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            setPortionTitle('Порций');
                            break;
                        case 1:
                            setPortionTitle('Порцию');
                            break;
                        case 2:
                        case 3:
                        case 4:
                            setPortionTitle('Порции');
                            break;
                        default:
                            break;
                    }
                    setRecipe(sr);
                    setProducts(sr.products);
                    setSteps(sr.steps);
                    setInit(true)
                }
            }
        }
    }, [init, recipes])
    useEffect(() => {
        let c = 0;
        let ugl = 0;
        let jir = 0;
        let protein = 0;
        let weight = 0;

        products.forEach(p => {
            c += p.product.calorie/100*p.weight;
            ugl += p.product.carbs/100*p.weight;
            jir += p.product.fat/100*p.weight;
            protein += p.product.protein/100*p.weight;
            weight += p.weight;
        })

        setRecipeCalorie(c);
        setRecipeCarbs(ugl);
        setRecipeFat(jir);
        setRecipeProtein(protein);
        setRecipeWeight(weight);
    }, [products.length])

    if (recipe === undefined) {
        return <Component>
            <span>Рецепт не найден</span>
        </Component>
    }

    if (recipe === null) {
        return <span>Загрузка рецепта</span>
    }

    return <Component>
        <LargeTitle>{recipe.name}</LargeTitle>
        {recipe.image !== null && <Cover><CoverImage width='100%' height='100%' image={recipe.image !== null ? recipe.image.path : null}/></Cover>}
        <span>{recipe.description}</span>
        <Columns>
            <span>Ссылка на рецепт:</span>
            <a href={recipe.external_url}>{recipe.external_url}</a>
        </Columns>
        <Title>Ингредиенты на {recipePortionCount} {portionTitle}</Title>
        <ProductList>
        {
            products.map((product, index) => <ProductItem>
                <span>{product.name}</span>
                <ProductItemLine />
                <span>{product.weight} г.</span>
            </ProductItem>)
        }
        </ProductList>
        <Title>Энергетическая ценность</Title>
        {/* <NutrientsList>
            <NutrientItem>
                <NutrientTitle>Калории</NutrientTitle>
                <NutrientValue color='#1e7390'>{Number((recipeCalorie/recipePortionCount).toFixed(2)).toString()} ({recipeCalorie})</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Белки</NutrientTitle>
                <NutrientValue color='#07bc0c'>{Number((recipeProtein/recipePortionCount).toFixed(2)).toString()} ({recipeProtein})</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Жиры</NutrientTitle>
                <NutrientValue color={Colors.peach}>{Number((recipeFat/recipePortionCount).toFixed(2)).toString()} ({recipeFat})</NutrientValue>
            </NutrientItem>
            <NutrientItem>
                <NutrientTitle>Углеводы</NutrientTitle>
                <NutrientValue color={Colors.red}>{Number((recipeCarbs/recipePortionCount).toFixed(2)).toString()} ({recipeCarbs})</NutrientValue>
            </NutrientItem>
        </NutrientsList> */}
        
        <NutrientsList>
            <Nutrients
                hideTitle={true}
                calorie={Number(recipeCalorie)}
                protein={Number(recipeProtein)}
                fat={Number(recipeFat)}
                carbs={Number(recipeCarbs)} 
                portionCount={recipePortionCount}/>
        </NutrientsList>
        <SmallText>Значение на 1 порцию (общее значение)</SmallText>
        
        <Title>Процесс приготовления</Title>
            <StepList>
            {
                steps.length !== 0 && steps.map((step, index) => <Step data={step}/>)
            }
            </StepList>
            <Centered>
            <Title>Приятного аппетита!</Title>
            </Centered>
    </Component>
}

export default inject("store")(observer(Page));