import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Modals, Colors, Device } from "../../constants";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Item from "./item";
import { toJS } from "mobx";

const Component = styled.div`
    padding: 3rem 2rem;
    overflow: auto;

    @media ${Device.mobileS} {
        padding: 2rem 1rem;
    }

    @media ${Device.tablet} {
        padding: 2rem 1rem;
    }

    @media ${Device.laptopS} {
        padding: 2rem 1rem;
    }

    @media ${Device.laptopM} {
        padding: 2rem 1.5rem;
    }

    @media ${Device.laptopL} {
        padding: 3rem 2rem;
    }
`;
const RecipeList = styled.div`
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    gap: 2rem;
    display: flex;
    width: 100%;
    align-content: space-around;

    @media ${Device.mobileS} {
        gap: 1rem;
    }

    @media ${Device.tablet} {
        gap: 1rem;
    }

    @media ${Device.laptopS} {
        gap: 1rem;
    }

    @media ${Device.laptopM} {
        gap: 1rem;
    }

    @media ${Device.laptopL} {
        gap: 2rem;
    }
`;
const ButtonCreateRecipe = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: .5rem;
    border: 1px solid lightgray;
    width: calc((1920px - 4rem) / 5);
    height: 250px;
    background-color: white;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: ${Colors.green};
        border: 1px solid ${Colors.green};
    }

    

    @media ${Device.tablet} {
        max-width: calc((768px - 4rem) / 3);
        height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        max-width: calc((1000px - 4rem) / 4);
        height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        max-width: calc((1200px - 2rem) / 5);
        height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        max-width: calc((1920px - 4rem) / 5);
        height: 250px;
        font-size: 2rem;
    }
`;


function Recipes(props) {
    const recipes = toJS(props.store.Recipes.recipes);
    const navigate = useNavigate();
    return <Component>
        <RecipeList>
            <ButtonCreateRecipe onClick={() => props.store.Global.setModal(Modals.create_recipe.name)}>
                <span>Добавить рецепт</span>
            </ButtonCreateRecipe>
            {
        recipes.map(recipe => <Item data={recipe} {...props} onClick={() => navigate('/recipe/'+recipe.id)}/>)
    }
        </RecipeList>
    
    </Component>
}

export default inject("store")(observer(Recipes));