import styled from "styled-components";
import { Colors, Device } from "../constants";

const Component = styled.button`
    border-radius: .25rem;
    border: 1px solid ${props => props.isActive ? Colors.green : 'lightgray'};
    padding: .25rem .5rem;
    outline: none;
    background-color: ${props => props.isActive ? Colors.green : 'buttonface'}; 
    color: ${props => props.isActive ? 'white': 'buttontext'}; ;
    cursor: pointer;

    &:hover {
        border: 1px solid ${Colors.green};
        background-color: ${Colors.green};
        color: white;
    }

    @media ${Device.mobileS} {
        font-size: .8rem;
    }

    @media ${Device.mobileM} {
        font-size: .8rem;
    }

    @media ${Device.mobileL} {
        font-size: .8rem;
    }

    @media ${Device.tablet} {
        font-size: .8rem;
    }

    @media ${Device.laptopS} {
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const GroupLeft = styled(Component)`
    border-right: none;
    border-top-left-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: 0;

    &:hover {
        border-right: none;
    }
`;
const GroupMiddle = styled(Component)`
    border-radius: 0;
    border-left: none;
    border-right: none;

    &:hover {
        border-left: none;
    border-right: none;
    }
`;
const GroupRight = styled(Component)`
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: .25rem;

    &:hover {
        border-left: none;
    }
`;
const Delete = styled(Component)`
    &:hover {
        border: 1px solid ${Colors.red};
        background-color: ${Colors.red};
        color: white;
    }
`; 
function Button(props) {
    if (props.isGroup) {
        if (props.isLeft) {
            return <GroupLeft {...props}>{props.children}</GroupLeft>
        }

        if (props.isRight) {
            return <GroupRight {...props}>{props.children}</GroupRight>
        }

        return <GroupMiddle {...props}>{props.children}</GroupMiddle>
    }

    switch (props.type) {
        case 'delete':
            return <Delete {...props}>{props.children}</Delete>;
        default:
            return <Component {...props}>{props.children}</Component>
    }
    
}

export default Button;