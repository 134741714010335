import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../components/button";
import Weekday from "../components/weekday";
import { Modals, Device } from "../constants";
const Component = styled.div`
    position: relative;
    flex-direction: column;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    //overflow: auto;
    height: 100%;

    @media ${Device.mobileS} {
        padding-top: 2rem;
    }

    @media ${Device.mobileM} {
        padding-top: 2rem;
    }

    @media ${Device.mobileL} {
        padding-top: 2rem;
    }

    @media ${Device.tablet} {
        padding-top: 1rem;
    }

    @media ${Device.laptopS} {
        padding-top: 1rem;
    }

    @media ${Device.laptopM} {
        padding-top: 1rem;
    }

    @media ${Device.laptopL} {
        padding-top: 1rem;
    }
`;
const MenuList = styled.div`
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    display: flex;
    align-items: baseline;
    height: 100%;
    overflow-x: auto;

    @media ${Device.mobileS} {
        gap: 1rem;
    }

    @media ${Device.mobileM} {
        gap: 1rem;
    }

    @media ${Device.mobileL} {
        gap: 1rem;
    }

    @media ${Device.tablet} {
        gap: 0.5rem;
    }

    @media ${Device.laptopS} {
        gap: 0.5rem;
    }

    @media ${Device.laptopM} {
        gap: 0.5rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const DateTitle = styled.span`
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;

    @media ${Device.mobileS} {
        font-size: 1.1rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.1rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.1rem;
    }

    @media ${Device.tablet} {
        font-size: 1.1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.25rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.5rem;
    }
`;
const HeadControls = styled.div`
    flex-direction: row;
    top: 0;
    left: 0;
    gap: .25rem;
    display: flex;
    position: sticky;
`;

function Menus(props) {
    const storeMenus = toJS(props.store.Menus.menus);
    const modalType = props.store.Global.modalType;
    const currentDayRef = useRef(null);
    const [startWeek, setStartWeek] = useState(null);
    const [endWeek, setEndWeek] = useState(null);
    const [weeks, setWeeks] = useState([]);
    const [dates, setDates] = useState([]);
    const [weekDate, setWeekDate] = useState(null);
    const [init, setInit] = useState(false);


    useEffect(() => {
        setInit(false);
    }, [modalType])

    useEffect(() => {
        setWeek(new Date().toISOString().substring(0,10));
    }, [])
    useEffect(() => {
        if (init) return;

        if (startWeek !== null && endWeek !== null) {
            let w = [];
            let dat = [];
            w.length = 7;
            let i = 0;
            const filteredList = storeMenus.filter(m => new Date(m.menu_date).toISOString().substring(0,10) >= new Date(startWeek).toISOString().substring(0,10) && new Date(m.menu_date).toISOString().substring(0,10) <= new Date(endWeek).toISOString().substring(0,10));
            for(i = 0; i < 7; i++) {
                const da = new Date(startWeek);
                da.setDate(da.getDate() + i)
                w[i] = filteredList.find(f => new Date(f.menu_date).toISOString().substring(0,10) === new Date(da).toISOString().substring(0,10));
                dat[i] = new Date(da).toISOString().substring(0,10);
            }
            //console.log(dat);
            setWeeks(w);
            setDates(dat);
            setInit(true);
        }
    },[storeMenus, init, startWeek, endWeek])

    useEffect(() => {
        setInit(false);
    }, [props.store.Menus.menus])

    function setWeek(date) {
        var curr = new Date(date); // get current date
        let day = curr.getDay()
        //let cd =  === 0 ? curr.getDay() - 6 : curr.getDay() + 1;
        var first = curr.getDate() - day + (day === 0 ? -6 : 1); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        const fd = new Date(curr.setDate(first));
        const d = new Date(fd);
        let l = new Date(curr.setDate(last));
        // TODO: BUG
        //l = l.getDate() < fd.getDate() ? l.setMonth(l.getMonth() + 1) : l;
        let s = '';
        if (d.getMonth() !== new Date(l).getMonth()) {
            s = new Intl.DateTimeFormat('ru-ru', {month: 'long'}).format(d)+' '+new Date(d).getDate()+' - '+new Intl.DateTimeFormat('ru-ru', {month: 'long'}).format(l-1)+' '+new Date(l).getDate()+', '+new Intl.DateTimeFormat('ru-ru', {year: 'numeric'}).format(d);
        }
        else {
            s = new Intl.DateTimeFormat('ru-ru', {month: 'long'}).format(d)+' '+new Date(d).getDate()+' - '+new Date(l).getDate()+', '+new Intl.DateTimeFormat('ru-ru', {year: 'numeric'}).format(d);
        }
        setWeekDate(s);
        setStartWeek(fd);
        setEndWeek(l);
    }

    function prevWeek() {
        let current = new Date(startWeek);
        let prev = current.getDate() - 7;
        const date = new Date(current.setDate(prev));
        setInit(false);
        setWeek(date);
    }

    function nextWeek() {
        let current = new Date(startWeek);
        let prev = current.getDate() + 7;
        const date = new Date(current.setDate(prev));
        setInit(false);
        setWeek(date);
    }

    return <Component>
        <HeadControls>
            <DateTitle onClick={() => currentDayRef.current.scrollIntoView()}>{weekDate}</DateTitle>
            <Button onClick={() => prevWeek()}>{'<'}</Button>
            <Button onClick={() => nextWeek()}>{'>'}</Button>
        </HeadControls>
        <MenuList>
        { /*
            filteredList.map(menu => <Weekday data={menu}/>)
        */}
            <Weekday key='wd_1' data={weeks[0]} weekday={dates[0]} onClick={() => props.store.Global.setModal(weeks[0] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[0],data: weeks[0]})}/>
            <Weekday  key='wd_2' data={weeks[1]} weekday={dates[1]} onClick={() => props.store.Global.setModal(weeks[1] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[1],data: weeks[1]})}/>
            <Weekday  key='wd_3' data={weeks[2]} weekday={dates[2]} onClick={() => props.store.Global.setModal(weeks[2] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[2],data: weeks[2]})}/>
            <Weekday  key='wd_4' data={weeks[3]} weekday={dates[3]} onClick={() => props.store.Global.setModal(weeks[3] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[3],data: weeks[3]})}/>
            <Weekday  key='wd_5' data={weeks[4]} weekday={dates[4]} onClick={() => props.store.Global.setModal(weeks[4] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[4],data: weeks[4]})}/>
            <Weekday  key='wd_6' data={weeks[5]} weekday={dates[5]} onClick={() => props.store.Global.setModal(weeks[5] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name,{weekday:dates[5],data: weeks[5]})}/>
            <Weekday  key='wd_7' data={weeks[6]} weekday={dates[6]} onClick={() => props.store.Global.setModal(weeks[6] !== undefined ? Modals.edit_menu.name : Modals.create_menu.name, {weekday:dates[6],data: weeks[6]})}/>
        </MenuList>
    </Component>;
}

export default inject("store")(observer(Menus));