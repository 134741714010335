import { makeObservable, observable, action } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Menus {
    menus = []

    constructor(title) { 
        makeObservable(this, {
            menus: observable,
            loadingData: action,
            create: action,
            update: action,
            delete: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'paprikman';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    loadingData() {
        axios.get(Config.api.host.paprikman[NODE_ENV]+Config.api.menus.get_all)
        .then(result => {
            if (result.data.status) {
                this.menus = result.data.data;
            }
            else {
                toast.error(result.data.message)
            }
        })
        .catch(error => {
            toast.error(error)
        })
    }

    create(data) {
        return axios.post(Config.api.host.paprikman[NODE_ENV]+Config.api.menus.create, data);
    }

    update(data) {
        return axios.put(Config.api.host.paprikman[NODE_ENV]+Config.api.menus.update, data);
    }

    delete(id) {
        return axios.delete(Config.api.host.paprikman[NODE_ENV]+Config.api.menus.delete+id);
    }
}

export default new Menus();