import { makeObservable, observable, action } from "mobx"

class Global {
    modalType = 'none';
    modalData = null;

    constructor(title) {
        makeObservable(this, {
            modalType: observable,
            setModal: action
        })
    }

    setModal(type, data) {
        if (type === undefined) {
            this.modalType = 'none';
            this.modalData = null;
            return;
        }

        this.modalType = type;
        this.modalData = data;
    }
}

export default new Global();