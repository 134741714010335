import styled from "styled-components";
import CoverImage from "./cover_image";
import { Colors, Device } from "../constants";

const Component = styled.div`
flex-direction: column;
    gap: .5rem;
    display: flex;
`;
const Title = styled.span`
    font-size: 1.6rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.25rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.25rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.75rem;
    }
`;
const SmallText = styled.span`
    font-size: smaller;
`;
const Cover = styled.div`
    margin: 0 auto;
    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 100%;
    }

    @media ${Device.laptopS} {
        width: 100%;
    }

    @media ${Device.laptopM} {
        width: 100%;
    }

    @media ${Device.laptopL} {
        width: 100%;
    }
`;
export default function Step(props) {
    const step = props.data;
    return <Component>
        <Title>Шаг {step.step_order}. {step.name}</Title>
        { step.image !== null && <Cover><CoverImage width='auto' height='auto' image={step.image !== null ? step.image.path : null}/></Cover>}
        <SmallText>{step.description}</SmallText>
    </Component>
}