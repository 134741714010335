import styled from 'styled-components';

const Component = styled.input`
    border: 1px solid lightgray;
    border-radius: .25rem;
    padding: .5rem;
    outline: none;
    width: ${props => props.width};
    font-family: 'Roboto',sans-serif;
`;
function Input(props) {
    const width = props.width !== undefined ? props.width : 'auto';
    return <Component width={width} {...props}/>
}

export default Input;