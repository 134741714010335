import styled from "styled-components";
import { Device } from "../constants";

const Component = styled.div`
    position: relative;
    font-family: 'Roboto',sans-serif;
    border: 1px solid lightgray;
    border-radius: .25rem;
`;

const CompInput = styled.textarea`
	width: 100%;
	padding: 10px 0;
	padding-left: .5rem;
	font-size: 16px;
	border: none;
	outline: none;
	background: transparent;	

	@media ${Device.mobileS} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.mobileM} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.mobileL} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.tablet} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.laptopS} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.laptopM} {
		font-size: 14px;
		padding: 6px 0;
		padding-left: .5rem;
	}

	@media ${Device.laptopL} {
		font-size: 16px;
		padding: 10px 0;
		padding-left: .5rem;
	}
`;

const CompLabel = styled.label`
	position: absolute;
	top:0;
	left: 0;
	padding: 10px 0;
	margin-left: .5rem;
	font-size: 16px;
	pointer-events: none;
	transition: .3s;

	@media ${Device.mobileS} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.mobileM} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.mobileL} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.tablet} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.laptopS} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.laptopM} {
		font-size: 14px;
		padding: 6px 0;
	}

	@media ${Device.laptopL} {
		font-size: 16px;
		padding: 10px 0;
	}

	${CompInput}:focus + & {
		top: -18px;
		left: 0;
		font-size: 12px;
		padding: 10px 5px;
		background-image: linear-gradient(to bottom, transparent, white, transparent);

	}

	${CompInput}:valid + & {
		top: -18px;
		left: 0;
		font-size: 12px;
		padding: 10px 5px;
		background-image: linear-gradient(to bottom, transparent, white, transparent);
	} 
`;


export default function Input(props) {
    return <Component>
        <CompInput {...props} required/>
        <CompLabel value={props.value}>{props.label}</CompLabel>
    </Component>
}