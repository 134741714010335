export const Colors = {
    green: '#AAE9C4',
    light_green: 'rgb(231 253 239 / 30%)',//'#E7FDEF',
    red: '#DD4A48',
    peach: '#ECB390'
}

export const Paths = {
    main: '/',
    menus: '/menus',
    recipes: '/recipes',
    products: '/products'
}

export const BREAKPOINTS = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptopS: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
};
export const Device = {
    mobileS: `(min-width: ${BREAKPOINTS.mobileS})`,
    mobileM: `(min-width: ${BREAKPOINTS.mobileM})`,
    mobileL: `(min-width: ${BREAKPOINTS.mobileL})`,
    tablet: `(min-width: ${BREAKPOINTS.tablet})`,
    laptopS: `(min-width: ${BREAKPOINTS.laptopS})`,
    laptopM: `(min-width: ${BREAKPOINTS.laptopM})`,
    laptopL: `(min-width: ${BREAKPOINTS.laptopL})`,
    desktop: `(min-width: ${BREAKPOINTS.desktop})`
  };

export const Modals = {
    create_product: {
        name: 'create_product',
        label: 'Добавить продукт'
    },
    create_recipe: {
        name: 'create_recipe',
        label: 'Добавить рецепт'
    },
    create_menu: {
        name: 'create_menu',
        label: 'Добавить меню'
    },
    edit_product: {
        name: 'edit_product',
        label: 'Редактирование продукта'
    },
    edit_recipe: {
        name: 'edit_recipe',
        label: 'Редактирование рецепта'
    },
    edit_menu: {
        name: 'edit_menu',
        label: 'Редактирование меню'
    }
}

export const Meals = {
    breakfast: {
        name: 'breakfast',
        label: 'Завтрак'
    },
    lunch: {
        name: 'lunch',
        label: 'Обед'
    },
    dinner: {
        name: 'dinner',
        label: 'Ужин'
    },
    afternoon_snack: {
        name: 'afternoon_snack',
        label: 'Полдник'
    },
    snack: {
        name: 'snack',
        label: 'Перекусы'
    },
    day_meal: {
        name: 'day_meal',
        label: 'Блюдо дня'
    }
}

export const Config = {
    env: process.env.NODE_ENV || 'production',
    api: {
        host: {
            common: {
                development: 'http://localhost:8100/',
                production: 'https://tsvetkov.one:8100/'
            },
            paprikman: {
                development: 'http://localhost:8130/',
                production: 'https://paprikman.tsvetkov.one:8130/'
            }
        },
        common: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
        images: {
            create: 'api/image',
            get_all: 'api/images',
            update: 'api/image',
            delete: 'api/image/'
        },
        menus: {
            create: 'api/menu',
            get_all: 'api/menus',
            update: 'api/menu',
            delete: 'api/menu/'
        },
        menu_items: {
            create: 'api/menuItem',
            get_all: 'api/menuItems',
            update: 'api/menuItem',
            delete: 'api/menuItem/'
        },
        products: {
            create: 'api/product',
            get_all: 'api/products',
            update: 'api/product',
            delete: 'api/product/'
        },
        recipes: {
            create: 'api/recipe',
            get_all: 'api/recipes',
            update: 'api/recipe',
            delete: 'api/recipe/'
        },
        recipe_steps: {
            create: 'api/recipeStep',
            get_all: 'api/recipeSteps',
            update: 'api/recipeStep',
            delete: 'api/recipeStep/'
        },
        recipe_products: {
            create: 'api/recipeProduct',
            get_all: 'api/recipeProducts',
            update: 'api/recipeProduct',
            delete: 'api/recipeProduct/'
        },
        main: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
    }
}