import styled from "styled-components";

const Component = styled.textarea`
    border: 1px solid lightgray;
    border-radius: .25rem;
    padding: .5rem;
    outline: none;
    font-family: 'Roboto',sans-serif;
`;

export default function Textarea(props) {
    return <Component {...props} />
}