import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as vars from '../constants';
import { Device } from '../constants';

const Component = styled.div`
    //position: relative;
    flex-direction: row;
    flex: 1;
    gap: 1rem;
    justify-content: end;
    align-items: center;
    display: flex;

    @media ${Device.mobileS} {
        flex-direction: column;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }


    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;
const MenuItem = styled.div`
    position: relative;
    justify-content: center;
    display: ${props => props.visible ? 'flex' : 'none'};
    align-items: center;
    background-color: ${props => props.isActive ? vars.Colors.green : 'white'};
    height: ${props => props.isActive ? '150px' : '100px'};
    border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
    border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
    min-width: ${props => props.isActive ? '250px' : '100px'};
    font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
    font-size:  ${props => props.isActive ? '3rem' : '1.5rem'};
    padding: 1rem;
    color: ${props => props.isActive ? 'white' : 'black'};
    cursor: pointer;
    user-select: none;
    box-shadow: ${props => props.isActive ? 'box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;' : 'none'};
    z-index: 1000;

    @media ${Device.mobileS} {
        display: ${props => props.isActive  || (props.isRecipe && props.isActive) ? 'flex' : 'none'};;
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '125px' : '75px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }

    @media ${Device.mobileM} {
        display: ${props => props.isActive  || (props.isRecipe && props.isActive) ? 'flex' : 'none'};;
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '125px' : '75px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }

    @media ${Device.mobileL} {
        display: ${props => props.isActive || (props.isRecipe && props.isActive) ? 'flex' : 'none'};;
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '125px' : '75px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }

    @media ${Device.tablet} {
        display: ${props => !props.isRecipe ? 'flex' : 'none'};
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '125px' : '75px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }

    @media ${Device.laptopS} {
        display: ${props => !props.isRecipe ? 'flex' : 'none'};
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '150px' : '100px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }


    @media ${Device.laptopM} {
        display: ${props => !props.isRecipe ? 'flex' : 'none'};
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '150px' : '100px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
        padding: 1rem;
    }

    @media ${Device.laptopL} {
        display: ${props => !props.isRecipe ? 'flex' : 'none'};
        height: ${props => props.isActive ? '100px' : '50px'};
        border-bottom-left-radius: ${props => props.isActive ? '.75rem' : '0'};
        border-bottom-right-radius: ${props => props.isActive ? '.75rem' : '0'};
        min-width: ${props => props.isActive ? '150px' : '100px'};
        font-family: ${props => props.isActive ? '\'Amatic SC\', cursive' : '\'Roboto\', sans-serif'};
        font-size:  ${props => props.isActive ? '2rem' : '1rem'};
    }
`;

const MobileMenu = styled.div`
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    gap: .5rem;
    //z-index: 1000;
    background-color: white;
    border-top: 1px solid lightgray;
    padding: 2rem 1rem;
    z-index: 999;

    &:after {
        position: absolute;
        content: ' ';
        top: 100%;
        left: 0;
        height: 500px;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
    }

    @media ${Device.mobileS} {
        display: ${props => props.visible ? 'flex' : 'none'};
    }

    @media ${Device.mobileM} {
        display: ${props => props.visible ? 'flex' : 'none'};
    }

    @media ${Device.mobileL} {
        display: ${props => props.visible ? 'flex' : 'none'};
    }

    @media ${Device.tablet} {
        display: none;
    }

    @media ${Device.laptopS} {
        display: none;
    }


    @media ${Device.laptopM} {
        display: none;
    }

    @media ${Device.laptopL} {
        display: none;
    }
`;
const MobileMenuItem = styled.span`
    padding: .5rem;
    width: calc(100% - 1rem);
    text-align: center;
    border: 1px solid lightgray;
    border-radius: .25rem;
    background-color: ${props => props.isActive ? vars.Colors.green : 'white'};
    color: ${props => props.isActive ? 'white' : 'black'};
    //border-bottom: 1px solid lightgray;
    //border-top: 1px solid lightgray;
`;
const TranparentButton = styled.div`
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
    overflow: hidden;

    @media ${Device.mobileS} {
        display: block;
    }

    @media ${Device.mobileM} {
        display: block;
    }

    @media ${Device.mobileL} {
        display: block;
    }

    @media ${Device.tablet} {
        display: none;
    }

    @media ${Device.laptopS} {
        display: none;
    }


    @media ${Device.laptopM} {
        display: none;
    }

    @media ${Device.laptopL} {
        display: none;
    }
`;

function Menu(props) {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return <Component>
        <MenuItem isRecipe={false} isActive={pathname === vars.Paths.main} onClick={() => navigate(vars.Paths.main)}><TranparentButton onClick={() => setShowMobileMenu(!showMobileMenu)}/>Главная</MenuItem>
        <MenuItem isRecipe={false} isActive={pathname === vars.Paths.menus} onClick={() => navigate(vars.Paths.menus)}><TranparentButton onClick={() => setShowMobileMenu(!showMobileMenu)}/>Меню</MenuItem>
        <MenuItem isRecipe={false} isActive={pathname === vars.Paths.recipes} onClick={() => navigate(vars.Paths.recipes)}><TranparentButton onClick={() => setShowMobileMenu(!showMobileMenu)}/>Рецепты</MenuItem>
        <MenuItem isRecipe={true} isActive={pathname.includes('/recipe/')} onClick={null}><TranparentButton onClick={() => setShowMobileMenu(!showMobileMenu)}/>Рецепт</MenuItem>
        <MenuItem isRecipe={false} isActive={pathname === vars.Paths.products} onClick={() => navigate(vars.Paths.products)}><TranparentButton onClick={() => setShowMobileMenu(!showMobileMenu)}/>Продукты</MenuItem>
        <MobileMenu visible={showMobileMenu}>
            <MobileMenuItem isActive={pathname === vars.Paths.main} onClick={() => {setShowMobileMenu(false); navigate(vars.Paths.main)}}>Главная</MobileMenuItem>
            <MobileMenuItem isActive={pathname === vars.Paths.menus} onClick={() => {setShowMobileMenu(false); navigate(vars.Paths.menus)}}>Меню</MobileMenuItem>
            <MobileMenuItem isActive={pathname === vars.Paths.recipes} onClick={() => {setShowMobileMenu(false); navigate(vars.Paths.recipes)}}>Рецепты</MobileMenuItem>
            <MobileMenuItem isActive={pathname === vars.Paths.products} onClick={() => {setShowMobileMenu(false); navigate(vars.Paths.products)}}>Продукты</MobileMenuItem>
        </MobileMenu>
    </Component>
}

export default Menu;